import React, { useState } from 'react';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import CreateDocument from '../CreateDocument/CreateDocument';
import CustomModal from '../CustomModal/CustomModal';
import DocumentsList from '../DocumentsList/DocumentsList';


const DocumentsView = () => {

    useGuestRedirection();

    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    return (
        <>
            <DocumentsList />
            <CustomModal isActive={isModalOpen} title="Create Document" handleClose={() => setModalOpen(false)}>
                <CreateDocument onComplete={() => setModalOpen(false)} />
            </CustomModal>
        </>
    );
};

export default DocumentsView;
