import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useCreateReferenceMutation, ReferenceTypes } from '../../generated/graphql';
import { useChangeHandler } from '../../services/Hooks';
import { LINKS } from '../../services/Queries';

const CreateLink = ({ onComplete }: { onComplete: any }) => {

    const [link, setLink] = useState();
    const [createLink] = useCreateReferenceMutation()
    const changeHandler = useChangeHandler(link, setLink);

    const handleSubmit = () => {
        createLink({ variables: { data: { ...link, type: ReferenceTypes.Link } }, refetchQueries: [{ query: LINKS, variables: { page: 1 } }] }).then(() => onComplete());
    };

    return (
        <Grid container direction="column">
            <TextField required name="url" placeholder="url" onChange={changeHandler} />
            <TextField required name="name" placeholder="name" onChange={changeHandler} />
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <Button onClick={handleSubmit}>Create Link</Button>
        </Grid>
    );
};

export default CreateLink;
