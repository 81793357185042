import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useState } from 'react';
import { Comment, useCreateCommentMutation, useFleetingNotesQuery, useRemoveCommentMutation } from '../../generated/graphql';
import { useGetPage, useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import CreateFleetingNote from '../CreateFleetingNote/CreateFleetingNote';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import Toolbar from '../Toolbar/Toolbar';

const FleetingNotePreview = ({ tag, onRemove }: { tag: any, onRemove: any }) => {
    return (
        <Card>
            <CardHeader title={tag.title} />
            <CardContent>
                <p>{tag.description ? tag.description : 'Sem Descrição.'}</p>
            </CardContent>
            <IconButton onClick={() => onRemove(tag)}>
                <DeleteIcon />
            </IconButton>
        </Card>
    );
};


const FleetingNotesList = ({ tags, onRemove }: { tags: any, onRemove: any }) => (
    <Grid item xs={10}>
        <Grid container direction="row" spacing={8} justify="space-between" alignItems="center">
            {tags.results.map(
                (tag: any) => (
                    <Grid key={tag.id} item xs={2}>
                        <FleetingNotePreview tag={tag} onRemove={onRemove} />
                    </Grid>
                )
            )}
        </Grid>
    </Grid>
);


const FleetingNotesView = () => {

    useGuestRedirection();

    const page = useGetPage();
    const [filters, setFilters] = useState({ page, showFilters: false, search: '' });
    const [createComment] = useCreateCommentMutation()

    const { loading, data, refetch } = useFleetingNotesQuery({ variables: { ...filters } })
    const [removeComment] = useRemoveCommentMutation()
    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const handleSubmit = (comment: Comment) => {
        setModalOpen(false);
        // @ts-ignore
        createComment({ variables: { data: { ...comment } } }).then(refetch);
    };

    const handleRemoveComment = (comment: Comment) => {
        removeComment({ variables: { data: { comment: comment.id } } }).then(() => refetch())
    };


    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Toolbar>
                <ScopedSearch onSearch={search} value={filters.search} />
            </Toolbar>
            <FleetingNotesList tags={data?.comments} onRemove={handleRemoveComment} />
            <CustomModal isActive={isModalOpen} title="Create Fleeting Note" handleClose={() => setModalOpen(false)}>
                <CreateFleetingNote onComplete={() => setModalOpen(false)} handleSubmit={handleSubmit} />
            </CustomModal>
        </Grid>
    );
};

export default FleetingNotesView;
