import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import GistDetails from '../GistDetails/GistDetails';
import styles from './GistDetailsView.module.scss';

const GistDetailsView = (props: any) => (
    <Grid container justify="center">
        <Card className={styles.GistCard}>
            <CardContent>
                <GistDetails id={props.match.params.id} />
            </CardContent>
        </Card>
    </Grid>
);

export default GistDetailsView;
