import React, { useState } from 'react';
import Dropzone from "react-dropzone";
import { ReferenceTypes, useCreateReferenceMutation } from '../../generated/graphql';
import { GRAPHICS } from '../../services/Queries';
import Loading from '../Loading/Loading';
import styles from './CreateReference.module.scss';

const CreateReference = ({ onComplete }: { onComplete: any }) => {

    const [createReference] = useCreateReferenceMutation()
    const [loading, setLoading] = useState(false);

    const getFileType = (file: any) => {
        return file.type === 'video/mp4' ? ReferenceTypes.Video : ReferenceTypes.Image
    }

    const createMutationRequest = (file: any) => {
        return createReference({
            variables: { data: { file, type: getFileType(file) } },
            refetchQueries: [{
                query: GRAPHICS,
                variables: { page: 1 }
            }]
        });
    };

    const drop = (files: any) => {
        if (files) {
            const promisses = files.map(createMutationRequest);
            setLoading(true);
            Promise.all(promisses).then(onComplete);
        }
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={styles.CreateReference}>
            <Dropzone onDrop={drop}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className={styles.Dropzone}>
                        <input {...getInputProps()} data-cy="dropzone" />
                    </div>
                )}
            </Dropzone>
        </div>
    );
};

export default CreateReference;
