import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';
import styles from './InfoBar.module.scss';

const InfoBar = ({ reference, updateReference, loading }: { reference: any, updateReference: any, loading: any }) => {

    const AUTOSAVE_INTERVAL = 3000;
    const [lastData, setLastData] = useState(null);
    const [data, setData] = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (lastData !== data) {
                // @ts-ignore
                updateReference({ variables: { data: { reference: reference.id, name: data.name, description: data.description } } });
                setLastData(data);
            }
        }, AUTOSAVE_INTERVAL);
        return () => window.clearTimeout(timer);
    }, [data]);

    const changeHandler = (event: any) => {
        // @ts-ignore
        setData({ ...data, [event.target.name]: event.target.value });
    };

    if (reference && data === null) {
        setData(reference);
    }

    return (
        <Grid container direction="column" className={styles.InfoBar}>
            <span className={styles.Saving}>{loading ? '...saving' : 'all saved'}</span>
            <TextField
                name="name"
                label="name"
                type="text"
                className={styles.InfoRow}
                // @ts-ignore
                value={data !== null ? data.name : ''}
                onChange={changeHandler}
            />
            <TextField
                name="description"
                label="description"
                type="text"
                multiline
                className={styles.InfoRow}
                // @ts-ignore
                value={data !== null ? data.description : ''}
                onChange={changeHandler}
            />
        </Grid>
    );
};

export default InfoBar;
