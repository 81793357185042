import { Card, CardContent, CardMedia } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../assets/images/exploration.png';
import { snakeToCamel } from '../../services/Utils';
import ReadIcon from '../ReadIcon/ReadIcon';
import styles from './ExplorationPreview.module.scss';

// @ts-ignore
const ExplorationPreview = ({ exploration, selected, ...props }) => {
    const to = `/explorations/${exploration.id}`;

    const handleSelect = (event: any) => {
        event.preventDefault();
        const action = selected ? 'remove' : 'add';
        props.handleSelect(exploration.id, action);
    };

    const classes = () => {
        const classes = [styles.ReadIcon];
        const state_name = snakeToCamel(exploration.state.toLowerCase());
        classes.push(styles[state_name]);
        return classes;
    };

    return (
        <div className={classNames(styles.ExplorationPreview, { [styles.Selected]: selected })}>
            <Link to={to}>
                <Card style={{ position: 'relative' }}>
                    <ReadIcon className={classNames(classes())} />
                    <CardMedia
                        image={image}
                        title={exploration.name}
                        className={styles.ExplorationImage}
                    />
                    <CardContent className={styles.CardContent}>
                        <Grid container justify="space-between" alignItems="center">
                            <span className={styles.Name}>#{exploration.id} {exploration.name}</span>
                            <div style={{ position: 'relative' }}>
                                <small style={{ position: 'absolute', top: '-5px', left: '30px', fontSize: '10px' }}>{exploration.prayers}</small>
                            </div>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container direction="column" className={styles.Overlay} justify="space-between">
                    <Icon className={classNames(styles.CheckIcon, { [styles.CheckIconActive]: selected })} onClick={handleSelect}>
                        <CheckCircleIcon />
                    </Icon>
                    <div className={styles.Tags}>
                        {exploration.explorationTags.map((explorationTag: any) => explorationTag.tag.id).join('; ')}
                    </div>
                </Grid>
            </Link>
        </div>
    );
};

export default ExplorationPreview;
