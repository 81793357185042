import { useMutation } from '@apollo/react-hooks';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import classNames from 'classnames';
import { Maybe } from 'graphql/jsutils/Maybe';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useClipboard } from 'use-clipboard-copy';
import { Comment, Exploration, useCreateCommentMutation, useCreateExplorationReferenceMutation, useCreateExplorationTagMutation, useExplorationQuery, useRemoveCommentMutation, useUpdateCommentMutation, useUpdateExplorationMutation } from '../../generated/graphql';
import { Config } from '../../services/Config';
import { REMOVE_EXPLORATION_TAG } from '../../services/Queries';
import { getCollectionLink, getNextExplorationState, snakeToCamel } from '../../services/Utils';
import AutoTextField from '../AutoTextField/AutoTextField';
import CustomModal from '../CustomModal/CustomModal';
import ExplorationReference from '../ExplorationReference/ExplorationReference';
import Loading from '../Loading/Loading';
import ReadIcon from '../ReadIcon/ReadIcon';
import ReferenceComment from '../ReferenceComment/ReferenceComment';
import TagSelector from '../TagSelector/TagSelector';
import styles from './ExplorationDetails.module.scss';
import useDocumentTitle from '@rehooks/document-title';

const ReferencesTable = ({ exploration, remove }: { exploration: any, remove?: any }) => {
    return (
        <div className={styles.ReferencesTable}>
            {exploration?.explorationReferences && exploration.explorationReferences.map(
                (explorationReference: any) =>
                    <div key={Math.random()}>
                        <Link to={getCollectionLink(explorationReference.reference)}>
                            #{explorationReference.reference.id} {explorationReference.reference.type} {explorationReference.reference.name}
                        </Link>
                    </div>)}
        </div>
    );
};

const ExplorationDetails = ({ id }: { id: any }) => {
    const { data, loading, refetch } = useExplorationQuery({ variables: { id } })
    const [updateExploration] = useUpdateExplorationMutation()
    const [updateComment] = useUpdateCommentMutation()
    const [createComment] = useCreateCommentMutation()
    const [deleteComment] = useRemoveCommentMutation()
    const [createExplorationReference] = useCreateExplorationReferenceMutation()
    const [createExplorationTag] = useCreateExplorationTagMutation()
    const [removeExplorationTag] = useMutation(REMOVE_EXPLORATION_TAG);

    const [isModalOpen, setModalOpen] = useState(false);

    const clipboard = useClipboard({ copiedTimeout: 600 });

    useDocumentTitle(data?.exploration?.name ? data.exploration.name : 'Refman');

    if (loading) {
        return <Loading />;
    }

    const exploration: Maybe<Exploration> = data?.exploration

    const handleToggle = () => {
        if (exploration?.state) {
            const state = getNextExplorationState(exploration.state);
            updateExploration({ variables: { data: { exploration: id, state } } }).then(() => refetch());
        }
    };

    const addNewComment = (x: any, y: any) => {
        // @ts-ignore
        createComment({ variables: { data: { exploration: exploration.id, x, y, description: '' } } }).then(refetch);
    };

    const handleClick = (event: any) => {
        const x = event.nativeEvent.clientX;
        const y = event.nativeEvent.clientY;
        addNewComment(x, y);
    };

    const handleRemove = (comment: Comment) => {
        // @ts-ignore
        deleteComment({ variables: { data: { comment: comment.id } } }).then(refetch);
    };

    const handleUpdateComment = (data: any) => {
        const { id, ...restData } = data
        updateComment({ variables: { data: { comment: id, ...restData } } });
    };

    const classes = () => {
        const classes = [styles.ReadIcon];
        // @ts-ignore
        const state_name = snakeToCamel(exploration.state.toLowerCase());
        classes.push(styles[state_name]);
        return classes;
    };

    const handleSave = (data: any) => {
        // @ts-ignore
        updateExploration({ variables: { data: { exploration: exploration.id, ...data } } });
    };

    const handleQuote = () => {
        // @ts-ignore
        const quote = `[${exploration.name}](${Config.APP_URL + '/explorations/' + exploration.id} "Open in Refman")`;
        clipboard.copy(quote);
    };

    const handleAddReference = () => {
        setModalOpen(true);
    };

    const addReference = (data: any) => {
        setModalOpen(false);
        if (exploration) {
            createExplorationReference({ variables: { data: { exploration: exploration?.id, reference: data } } }).then(() => refetch());
        }
    };

    const updateTags = (event: any, value: any, reason: any) => {

        if (reason === 'select-option') {
            const tag = value[value.length - 1];
            createExplorationTag({
                // @ts-ignore
                variables: { data: { exploration: exploration.id, tag: tag.id } }
                // @ts-ignore
            }).then(refetch);
        }

        if (reason === 'remove-option') {
            // @ts-ignore
            const oldTags = exploration.explorationTags.map((explorationTag: any) => explorationTag.tag.id);
            const newTags = value.map((tag: any) => tag.id);
            let tag = oldTags.filter((x: any) => !newTags.includes(x));
            removeExplorationTag({
                // @ts-ignore
                variables: { data: { exploration: exploration.id, tag: tag[0] } }
                // @ts-ignore
            }).then(refetch);
        }
    };

    const tags = () => {
        if (exploration?.explorationTags?.length) {
            return exploration.explorationTags.map((item: any) => item.tag)
        }
    }



    return (
        <>
            <CustomModal isActive={isModalOpen} title="Add Reference" handleClose={() => setModalOpen(false)}
            >
                <ExplorationReference addReference={addReference} />
            </CustomModal>
            <Grid container direction="column">
                <Card>
                    <CardHeader title={exploration?.name} />
                    <CardContent>
                        <Grid container direction="row">
                            <Grid item xs={6}>
                                <AutoTextField
                                    name="description"
                                    label="description"
                                    type="text"
                                    multiline
                                    fullWidth
                                    value={exploration?.description}
                                    onSave={handleSave}
                                />
                                <AutoTextField
                                    name="summary"
                                    label="summary"
                                    type="text"
                                    multiline
                                    fullWidth
                                    // @ts-ignore
                                    value={exploration.summary}
                                    onSave={handleSave}
                                />
                                <TagSelector value={tags()} onChange={updateTags} />
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container justify="center">
                                    <ReferencesTable exploration={exploration} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Grid container justify="flex-end" alignItems="center">
                            <IconButton onClick={handleAddReference}>
                                <AddIcon />
                            </IconButton>
                            <IconButton onClick={handleQuote}>
                                <FormatQuoteIcon />
                            </IconButton>
                            <IconButton color="primary" className={styles.ToggleRead} onClick={handleToggle}>
                                <ReadIcon className={classNames(classes())} tooltip={exploration?.state} />
                            </IconButton>
                        </Grid>
                    </CardActions>
                </Card>
                <div className={styles.CommentsBoard}>
                    <div onClick={handleClick} className={styles.CommentsArea}>
                        {exploration && exploration.comments && exploration.comments.map((comment: any) => (
                            <ReferenceComment
                                key={comment.id}
                                comment={comment}
                                remove={handleRemove}
                                update={handleUpdateComment}
                            />))
                        }
                    </div>
                    <h4>{exploration?.comments?.length} comments</h4>
                </div>
            </Grid>
        </>
    );
};

export default ExplorationDetails;
