import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState } from 'react';

// @ts-ignore
const AutoTextField = ({ onSave, value, name, ...props }) => {

    const AUTOSAVE_INTERVAL = props.interval || 2000;

    const [lastData, setLastData]: any = useState(null);
    const [data, setData]: any = useState(null);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const timer = window.setTimeout(() => {
            if (lastData[name] !== data[name]) {
                onSave(data);
                setLastData(data);
            }
        }, AUTOSAVE_INTERVAL);
        return () => window.clearTimeout(timer);
    }, [data]);

    const handleChange = (event: any) => {
        setData({ [name]: event.target.value });
    };

    if (!data) {
        setData({ [name]: value });
        setLastData({ [name]: value });
    }

    return (
        <TextField value={data && data[name] ? data[name] : ''}
            name={name}
            onChange={handleChange} {...props}
        />
    );
};

export default AutoTextField;
