import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useReferenceLanguagesQuery } from '../../generated/graphql';
import Loading from '../Loading/Loading';


const ReferenceLanguageFilter = ({ value, onFilter }: { value: any, onFilter: any }) => {

    const { data, loading } = useReferenceLanguagesQuery()

    if (loading) {
        return <Loading />
    }

    const options = data?.referenceLanguages?.results ? data.referenceLanguages.results : [];

    return (
        <Autocomplete
            id="combo-box-demo"
            options={options}
            onChange={onFilter}
            getOptionLabel={(option: any) => option?.name}
            style={{ width: 300, marginBottom: 15 }}
            renderInput={(params) => <TextField {...params} label="filter by language" />}
        />
    )
}


export default ReferenceLanguageFilter;
