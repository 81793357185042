import SvgIcon from '@material-ui/core/SvgIcon';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

const ReadIcon = (props: any) => {
    const data = "M22.614 11.028h-0.102c-0.435-2.2-2.404-3.862-4.732-3.862-1.279 0-2.43 0.512-3.299 1.304-0.767-0.23-1.611-0.358-2.481-0.358s-1.714 0.128-2.481 0.358c-0.87-0.818-2.021-1.304-3.299-1.304-2.327 0-4.271 1.662-4.732 3.862h-0.102c-0.537 0-0.972 0.435-0.972 0.972s0.435 0.972 0.972 0.972h0.102c0.435 2.2 2.404 3.862 4.732 3.862 2.66 0 4.834-2.148 4.834-4.834 0-0.639-0.128-1.253-0.358-1.816 0.409-0.077 0.844-0.128 1.304-0.128s0.895 0.051 1.304 0.128c-0.23 0.563-0.358 1.177-0.358 1.816 0 2.66 2.148 4.834 4.834 4.834 2.327 0 4.271-1.662 4.732-3.862h0.102c0.537 0 0.972-0.435 0.972-0.972s-0.435-0.972-0.972-0.972zM6.22 14.89c-1.586 0-2.89-1.304-2.89-2.89s1.304-2.89 2.89-2.89 2.89 1.304 2.89 2.89c0 1.586-1.304 2.89-2.89 2.89zM17.78 14.89c-1.586 0-2.89-1.304-2.89-2.89s1.304-2.89 2.89-2.89 2.89 1.304 2.89 2.89c0 1.586-1.304 2.89-2.89 2.89z";

    return (
        <Tooltip title={props.tooltip || ''} aria-label={props.tooltip || ''}>
            <SvgIcon {...props} style={{ fontSize: '18px', paddingBottom: '4px' }}>
                <path d={data} />
            </SvgIcon>
        </Tooltip>
    );
};

export default ReadIcon;
