import { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import querySearch from "stringquery";
import { FabContext } from '../services/FabProvider';

export const useChangeHandler = (obj: any, setObj: any, config: any = null) => {
    const func = (event: any) => {
        const field = event.target.name;
        const value = event.target.value;
        if (config && config.hasOwnProperty(field)) {
            const listValue = [value]
            setObj({ ...obj, [field]: listValue });
        } else {
            setObj({ ...obj, [field]: value });
        }

    }
    return func;
};

export const useGuestRedirection = () => {
    const history = useHistory();
    const location = useLocation();
    const auth_token = localStorage.getItem('auth.token')
    if (!auth_token) {
        history.push('/login', { redirectTo: location.pathname })
    }
};

export const useUserRedirection = () => {
    const history = useHistory();
    const auth_token = localStorage.getItem('auth.token');

    if (auth_token) {
        let url = '/';
        try {
            // @ts-ignore
            url = history.location.state.redirectTo;
        }
        catch (error) {
            console.error(error);
        }
        history.push(url);
    }
};

export const useSimplePagination = () => {

    const history = useHistory();
    // @ts-ignore
    const page = querySearch(history.location.search).page || 1
    const [obj, setObj] = useState({ page: parseInt(page) });

    const func = (event: any) => {
        const newState = { ...obj };
        const field = event.target.name;
        let value = event.target.value;
        if (field !== 'page') {
            newState['page'] = 1;
        }
        // @ts-ignore
        newState[field] = value;
        if (value === " ") {
            // @ts-ignore
            delete newState[field];
        }

        // update query param page
        history.push({
            search: '?page=' + newState.page
        })

        setObj(newState);
    }
    return [obj, func];
};

export const useGetPage = () => {
    const history = useHistory();
    // @ts-ignore
    const page = querySearch(history.location.search).page || 1
    return parseInt(page, 10);
}

export const usePagination = (obj: any, setObj: any) => {

    const history = useHistory();

    const func = (event: any) => {
        const newState = { ...obj };
        const field = event.target.name;
        let value = event.target.value;
        if (field !== 'page') {
            newState['page'] = 1;
        }
        newState[field] = value;
        if (value === " ") {
            delete newState[field];
        }

        // update query param page
        history.push({
            search: '?page=' + newState.page
        })

        setObj(newState);
    }
    return func;
};


/* eslint-disable react-hooks/exhaustive-deps */
export const useGlobalFab = (callback: any) => {
    const { setAction } = useContext(FabContext);
    useEffect(() => {
        setAction(() => callback);
        return () => setAction(() => null);
    }, [setAction]);
}

// https://stackoverflow.com/a/54159564
export const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
        // @ts-ignore
        htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
};
