import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import styles from '../CustomModal/CustomModal.module.scss';

const CustomModal = ({ isActive, children, title, handleClose }: { isActive: any, children: any, title: any, handleClose: any }) => {
    const fullWidth = true;
    const maxWidth = 'sm';
    return (
        <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            onClose={handleClose}
            className={styles.CustomModal}
            open={isActive}
        >
            <DialogTitle>{title}</DialogTitle>
            <IconButton className={styles.CloseAction} aria-label="close" onClick={handleClose}>
                <CloseIcon />
            </IconButton>
            <DialogContent onClick={event => event.stopPropagation()}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default CustomModal;
