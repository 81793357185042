import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { useGistsQuery, useRemoveReferenceMutation } from '../../generated/graphql';
import { useGetPage } from '../../services/Hooks';
import GistPreview from '../GistPreview/GistPreview';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import Toolbar from '../Toolbar/Toolbar';
import styles from './GistsList.module.scss';
import TagFilter from '../TagFilter/TagFilter';
import ReferenceLanguageFilter from '../ReferenceLanguageFilter/ReferenceLanguageFilter';

const GistsListContent = ({ data, filters, remove }: { data: any, filters: any, remove: any }) => {
    return (
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={8} className={styles.GistsList}>
            {data.references.results.length === 0 && <p className={styles.EmptyState}>Sem Referências.</p>}
            {data.references.results.map(
                (reference: any) =>
                    <Grid item xs={3} key={reference.id}>
                        <GistPreview gist={reference} remove={remove} />
                    </Grid>
            )}
        </Grid>
    );
};

const GistsList = () => {

    const page = useGetPage();
    const [filters, setFilters] = useState({ page, showFilters: false, language: '', search: '', tag: '' });
    const { loading, data, refetch } = useGistsQuery({ variables: filters })
    const [deleteReference] = useRemoveReferenceMutation()

    if (loading) {
        return <CircularProgress />;
    }

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };

    const handleDelete = (id: any) => {
        // @ts-ignore
        deleteReference({ variables: { id } }).then(refetch);
    };

    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    const handleTagFilter = (_: any, data: any) => {
        setFilters({ ...filters, tag: data.id });
        refetch();
    }

    const handleReferenceLanguageFilter = (_: any, data: any) => {
        setFilters({ ...filters, language: data.id });
        refetch();
    }

    return (

        <Grid container direction="column" alignItems="center">
            <Toolbar>
                <Grid container alignItems="center">
                    <ScopedSearch onSearch={search} value={filters.search} />
                    <TagFilter onFilter={handleTagFilter} value={filters.tag} />
                    <ReferenceLanguageFilter onFilter={handleReferenceLanguageFilter} value={filters.language} />
                </Grid>
            </Toolbar>
            <GistsListContent filters={filters} data={data} remove={handleDelete} />
            <Pagination count={data?.references?.count ? Math.ceil(data.references.count / 25) : 0} page={filters.page} onChange={handlePagination} style={{ marginTop: 25, marginBottom: 25 }} />
        </Grid>
    );
};

export default GistsList;
