import { useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { useCreateReferenceTagMutation, useGraphicsQuery, useRemoveReferenceMutation } from '../../generated/graphql';
import { useGetPage, useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import { UPDATE_REFERENCE } from '../../services/Queries';
import BulkActions from '../BulkActions/BulkActions';
import CreateReference from '../CreateReference/CreateReference';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import ReferencesList from '../ReferencesList/ReferencesList';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import TagFilter from '../TagFilter/TagFilter';
import TagReference from '../TagReference/TagReference';
import Toolbar from '../Toolbar/Toolbar';


const ReferencesListView = () => {

    useGuestRedirection();

    const page = useGetPage();
    // @ts-ignore
    const [filters, setFilters] = useState({ page, showFilters: false, search: '', tag: '' });
    const [selected, setSelected] = useState([]);
    const [deleteReference] = useRemoveReferenceMutation()
    const [createReferenceTag] = useCreateReferenceTagMutation()
    // @ts-ignore
    const { loading, data, refetch } = useGraphicsQuery({ variables: { ...filters } });
    const [updateReference] = useMutation(UPDATE_REFERENCE);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isTagModalOpen, setTagModalOpen] = useState(false);

    useGlobalFab(() => setCreateModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };

    const handleUpdate = ({ id, prayers }: { id: any, prayers: any }) => {
        updateReference({ variables: { id, prayers } });
    };

    const handleSelect = (id: any, action: any) => {
        if (action === 'add') {
            // @ts-ignore
            setSelected([...selected, id]);
        } else {
            const newSelected = selected.filter(el => el !== id);
            setSelected(newSelected);
        }
    };

    const deselect = () => {
        setSelected([]);
    };

    const bulkDelete = () => {
        deselect();
        // @ts-ignore
        const promisses = selected.map(
            id => deleteReference({ variables: { data: { reference: id } } }));
        // @ts-ignore
        Promise.all(promisses).then(refetch);
    };

    const bulkTag = () => {
        setTagModalOpen(true);
    };

    const tagReferences = (tags: any) => {
        setTagModalOpen(false);
        deselect();
        let promisses: any = []
        tags.map((tag: any) => {
            const p = selected.map(id => createReferenceTag({ variables: { data: { reference: id, tag } } }));
            promisses.concat(p)
        })
        Promise.all(promisses).then(() => refetch());
    };

    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    const handleTagFilter = (_: any, data: any) => {
        setFilters({ ...filters, tag: data.id });
        refetch();
    };

    const handleComplete = () => {
        setCreateModalOpen(false)
        refetch()
    }

    return (
        <Grid container direction="column" alignItems="center">
            <BulkActions
                visible={selected}
                selected={selected}
                deselect={deselect}
                bulkDelete={bulkDelete}
                bulkTag={bulkTag}
            />
            <Toolbar>
                <Grid container alignItems="center">
                    <ScopedSearch onSearch={search} value={filters.search} />
                    <TagFilter onFilter={handleTagFilter} value={filters.tag} />
                </Grid>
            </Toolbar>
            // @ts-ignore
            <ReferencesList references={data.references} handleUpdate={handleUpdate} selected={selected} handleSelect={handleSelect} />
            <Pagination count={data?.references?.count ? Math.ceil(data.references.count / 25) : 0} page={filters.page} onChange={handlePagination} style={{ marginTop: 25, marginBottom: 25 }} />
            <CustomModal isActive={isCreateModalOpen} title="Create Reference" handleClose={() => setCreateModalOpen(false)}>
                <CreateReference onComplete={handleComplete} />
            </CustomModal>
            <CustomModal isActive={isTagModalOpen} title="Tag" handleClose={() => setTagModalOpen(false)}>
                <TagReference tagReferences={tagReferences} />
            </CustomModal>
        </Grid>
    );
};

export default ReferencesListView;
