import { useMutation } from '@apollo/react-hooks';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { useTagsQuery } from '../../generated/graphql';
import { useGetPage, useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import { CREATE_TAG } from '../../services/Queries';
import CreateTag from '../CreateTag/CreateTag';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import TagsList from '../TagsList/TagsList';
import Toolbar from '../Toolbar/Toolbar';

const TagsListView = () => {

    useGuestRedirection();

    const page = useGetPage();
    const [filters, setFilters] = useState({ page, showFilters: false, search: '' });
    const [createTag] = useMutation(CREATE_TAG);
    // const handlePagination = usePagination(filters, setFilters);

    const { loading, data, refetch } = useTagsQuery({ variables: { ...filters } })
    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    if (loading) {
        return <Loading />;
    }

    const handleSubmit = (tag: any) => {
        setModalOpen(false);
        // @ts-ignore
        createTag({ variables: { data: { ...tag } } }).then(refetch);
    };

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };


    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    return (
        <Grid container direction="column" alignItems="center">
            <Toolbar>
                <ScopedSearch onSearch={search} value={filters.search} />
            </Toolbar>
            <TagsList tags={data?.tags} />

            <Pagination count={data?.tags?.count ? Math.ceil(data.tags.count / 25) : 0} page={filters.page} onChange={handlePagination} />

            <CustomModal isActive={isModalOpen} title="Create Tag" handleClose={() => setModalOpen(false)}>
                <CreateTag onComplete={() => setModalOpen(false)} handleSubmit={handleSubmit} />
            </CustomModal>
        </Grid>
    );
};

export default TagsListView;
