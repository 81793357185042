import { useMutation } from '@apollo/react-hooks';
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { useGuestRedirection } from '../../services/Hooks';
import { LOGOUT_USER } from '../../services/Local';
import styles from './Logout.module.scss';

const Logout = (props: any) => {

    useGuestRedirection();

    const [logoutUser] = useMutation(LOGOUT_USER);

    const handleSubmit = () => {
        logoutUser();
    };

    return (
        <Grid container justify="center" alignItems="center" className={styles.LogoutWrapper}>
            <Card className={styles.Logout}>
                <CardContent>
                    <Grid container direction="column" justify="center" alignItems="center">
                        <h1>Logout now</h1>
                        <p>If you want to logout from our site please click the button below<br />
                            You'll be redirected to the login page afterwards.</p>
                        <Button
                            data-cy="logout_submit"
                            color='primary'
                            variant='contained'
                            onClick={handleSubmit}
                            className={styles.Submit}
                        >
                            Logout now
                </Button>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default Logout;
