import React from 'react';
import styles from './Toolbar.module.scss';

const Toolbar = (props: any) => (
    <div className={styles.Toolbar}>
        {props.children}
    </div>
);

export default Toolbar;
