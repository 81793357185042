import TextField from '@material-ui/core/TextField';
import React from 'react';
import { capitalize } from '../../services/Utils';

const GenericTextField = (props: any) => {

    if (!props.name) {
        return null;
    }

    const type = props.type || "text";
    const label = props.label || capitalize(props.name);
    const data_cy = props.name;
    const errors = props.errors && props.errors[props.name];

    return (
        <TextField
            data-cy={data_cy}
            name={props.name} // its mandatory
            label={label} // optional
            margin="dense" // hardcoded
            type={type}
            onChange={props.changeHandler}
            required={props.required}
            error={errors && (errors.length > 0)}
            helperText={errors}
            fullWidth
            value={props.value}
            multiline={props.multiline || false}
        />
    );
};

export default GenericTextField;
