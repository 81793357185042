import Grid from '@material-ui/core/Grid';
import React from 'react';
import ReferenceComment from '../ReferenceComment/ReferenceComment';
import styles from './ReferenceDisplay.module.scss';
import { staticFile } from '../../services/Utils';

// @ts-ignore
const ReferenceDisplay = ({ reference, handleClick, ...props }) => {
    const image = staticFile(reference.file)
    const { comments } = reference;
    return (
        <Grid container justify="center" alignItems="flex-start" className={styles.ReferenceDisplay}>
            <img src={image} onClick={handleClick} alt='reference' />
            {
                comments && comments.map((comment: any) => <ReferenceComment key={comment.id} comment={comment} remove={props.remove} update={props.update} />)
            }
        </Grid>
    );
};

export default ReferenceDisplay;
