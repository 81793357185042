import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useChangeHandler } from '../../services/Hooks';

const CreateVideoComment = ({ played, create }: { played: any, create: any }) => {

    const [text, setText] = useState('');
    const changeHandler = useChangeHandler(text, setText);

    const onSubmit = (event: any) => {
        event.preventDefault();
        // @ts-ignore
        create(text.comment, played);
    };

    return (
        <div>
            <form onSubmit={onSubmit}>
                // @ts-ignore
                <TextField name="comment" multiple autoFocus onChange={changeHandler} fullWidth />
                <Button type="submit">Create Comment</Button>
            </form>
            <div>Comment will be at {played}</div>
        </div>
    );
};

export default CreateVideoComment;
