import { useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { useExplorationsQuery, useRemoveReferenceMutation } from '../../generated/graphql';
import { useGetPage } from '../../services/Hooks';
import { UPDATE_EXPLORATION_TAGS } from '../../services/Queries';
import BulkActions from '../BulkActions/BulkActions';
import CustomModal from '../CustomModal/CustomModal';
import ExplorationPreview from '../ExplorationPreview/ExplorationPreview';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import TagReference from '../TagReference/TagReference';
import Toolbar from '../Toolbar/Toolbar';
import styles from './ExplorationsList.module.scss';
import TagFilter from '../TagFilter/TagFilter';

const ExplorationsListContent = ({ data, filters, handleSelect, selected }: { data: any, filters: any, handleSelect: any, selected: any }) => {
    return (
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={8} className={styles.ExplorationsList}>
            {data.explorations.results.length === 0 && <p className={styles.EmptyState}>Sem Referências.</p>}
            {data.explorations.results.map(
                (exploration: any) =>
                    <Grid item xs={3} key={exploration.id}>
                        <ExplorationPreview exploration={exploration} handleSelect={handleSelect} selected={selected.find((el: any) => el === exploration.id)} />
                    </Grid>
            )}
        </Grid>
    );
};

const ExplorationsList = () => {

    const page = useGetPage();
    const [filters, setFilters] = useState({ page, showFilters: false, search: '', tag: '' });
    const [selected, setSelected] = useState([]);
    const { loading, data, refetch } = useExplorationsQuery({ variables: { ...filters } })
    const [deleteReference] = useRemoveReferenceMutation()
    const [isTagModalOpen, setTagModalOpen] = useState(false);
    const [updateExplorationTags] = useMutation(UPDATE_EXPLORATION_TAGS);

    if (loading) {
        return <CircularProgress />;
    }

    const handleSelect = (id: any, action: any) => {
        if (action === 'add') {
            // @ts-ignore
            setSelected([...selected, id]);
        } else {
            const newSelected = selected.filter(el => el !== id);
            setSelected(newSelected);
        }
    };

    const deselect = () => {
        setSelected([]);
    };

    const bulkDelete = () => {
        deselect();
        // @ts-ignore
        const promisses = selected.map(id => deleteReference({ variables: { id } }));
        // @ts-ignore
        Promise.all(promisses).then(refetch);
    };

    const bulkTag = () => {
        // @ts-ignore
        setTagModalOpen(true);
    };

    const tagExplorations = (tags: any) => {
        // @ts-ignore
        setTagModalOpen(false);
        deselect();
        const promisses = selected.map(id => updateExplorationTags({ variables: { exploration: id, tags: tags } }));
        // @ts-ignore
        Promise.all(promisses).then(refetch);
    };

    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };

    const handleTagFilter = (_: any, data: any) => {
        setFilters({ ...filters, tag: data.id });
        refetch();
    }

    return (
        <>
            <BulkActions
                visible={selected}
                selected={selected}
                deselect={deselect}
                bulkDelete={bulkDelete}
                bulkTag={bulkTag}
                selectedMsg="selected explorations"
            />
            <Toolbar>
                <Grid container alignItems="center">
                    <ScopedSearch onSearch={search} value={filters.search} />
                    <TagFilter onFilter={handleTagFilter} value={filters.tag} />
                </Grid>
            </Toolbar>
            <ExplorationsListContent filters={filters} data={data} handleSelect={handleSelect} selected={selected} />

            <Grid container justify="center" className={styles.PaginationContainer}>
                <Pagination count={data?.explorations?.count ? Math.ceil(data.explorations.count / 25) : 0} page={filters.page} onChange={handlePagination} />
            </Grid>

            <CustomModal isActive={isTagModalOpen} title="Tag" handleClose={() => setTagModalOpen(false)}>
                <TagReference tagReferences={tagExplorations} />
            </CustomModal>
        </>
    );
};

export default ExplorationsList;
