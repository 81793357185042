import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { useCreateCommentMutation, useGraphicQuery, useRemoveCommentMutation, useUpdateCommentMutation, useUpdateReferenceMutation } from '../../generated/graphql';
import ReferenceDisplay from '../ReferenceDisplay/ReferenceDisplay';
import styles from './ReferenceEditor.module.scss';
import InfoBar from '../InfoBar/InfoBar';
import useDocumentTitle from '@rehooks/document-title';

const ReferenceEditor = (props: any) => {

    const id = props.match.params.id;
    const { loading, data, refetch } = useGraphicQuery({ variables: { id } })

    const [updateComment] = useUpdateCommentMutation()
    const [createComment] = useCreateCommentMutation()

    const [deleteComment] = useRemoveCommentMutation()
    const [updateReferenceMutation, { loading: mutationLoading }] = useUpdateReferenceMutation()


    useDocumentTitle(data?.reference?.name ? data.reference.name : 'Refman');

    const addNewComment = (x: any, y: any) => {
        const reference = data?.reference?.id;
        console.log(x, y, reference)
        if (reference) {
            // @ts-ignore
            createComment({ variables: { data: { reference, x, y, description: '' } } }).then(refetch);
        }
    };

    const handleClick = (event: any) => {
        const x = event.nativeEvent.clientX;
        const y = event.nativeEvent.clientY;
        addNewComment(x, y);
    };

    const handleRemove = (comment: Comment) => {
        // @ts-ignore
        deleteComment({ variables: { data: { comment: comment.id } } }).then(refetch);
    };

    const handleUpdateComment = (data: any) => {
        const { id, ...restData } = data
        updateComment({ variables: { data: { comment: id, ...restData } } });
    };

    const updateReference = (data: any) => {
        updateReferenceMutation(data).then(() => refetch());
    };

    if (loading) {
        return <CircularProgress color="primary" />;
    }

    const reference = data?.reference;

    return (
        <Grid container justify="center" className={styles.ReferenceDisplayWrapper}>
            <Grid item xs={2}>
            </Grid>
            <Grid item xs={8}>
                <ReferenceDisplay
                    reference={reference}
                    handleClick={handleClick}
                    remove={handleRemove}
                    update={handleUpdateComment}
                />
            </Grid>
            <Grid item xs={2}>
                <InfoBar reference={reference} updateReference={updateReference} loading={mutationLoading} />
            </Grid>
        </Grid>
    );
};

export default ReferenceEditor;
