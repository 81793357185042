import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { useVideoQuery } from '../../generated/graphql';
import { CREATE_COMMENT } from '../../services/Queries';
import { staticFile } from '../../services/Utils';
import CreateVideoComment from '../CreateVideoComment/CreateVideoComment';
import CustomModal from '../CustomModal/CustomModal';
import Loading from '../Loading/Loading';
import styles from './VideoPlayer.module.scss';

const VideoComment = ({ played, comment }: { played: any, comment: any }) => {
    if (played > comment.time && played < comment.time + 4) {
        return (
            <div className={styles.Comment}>
                {comment.description}
            </div>
        );
    }
    return null
}

const VideoComments = ({ comments, played }: { comments: any, played: any }) => {
    return (
        <div className={styles.VideoComments}>
            {comments.map((comment: any) => <VideoComment key={Math.random()} played={played} comment={comment} />)}
        </div>
    );
};

const VideoPlayer = (props: any) => {

    const id = props.match.params.id;
    const { loading, data } = useVideoQuery({ variables: { id } })
    const [createComment] = useMutation(CREATE_COMMENT);
    const [played, setPlayed] = useState(0);
    const [playing] = useState(true);
    const [isModalOpen, setModalOpen] = useState(false);

    if (loading) {
        return <Loading />;
    }

    const onProgress = (played: any) => {
        setPlayed(played.playedSeconds);
    };

    const create = (description: any, time: any) => {
        createComment({ variables: { data: { reference: id, description, time } } }).then(() => setModalOpen(false));
    };

    const url = data?.reference?.file && staticFile(data?.reference?.file);

    return (
        <Grid container direction="column" justify="flex-start" alignItems="center" className={styles.VideoPlayer}>
            {url && <ReactPlayer url={url} onProgress={onProgress} playing={playing} controls width="70%" height="70%" />}
            <Button color="secondary" onClick={() => setModalOpen(true)}>Add Comment</Button>
            <CustomModal isActive={isModalOpen} title="Create Gist" handleClose={() => setModalOpen(false)}>
                <CreateVideoComment played={played} create={create} />
            </CustomModal>
            <VideoComments played={played} comments={data?.reference?.comments} />
        </Grid>
    );
};


export default VideoPlayer;
