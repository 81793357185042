import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useTagsQuery } from '../../generated/graphql';
import Loading from '../Loading/Loading';


const TagFilter = ({ value, onFilter }: { value: any, onFilter: any }) => {

    const { data, loading } = useTagsQuery({ variables: { pageSize: 9999 } });

    if (loading) {
        return <Loading />
    }

    const options = data?.tags?.results ? data.tags.results : [];

    return (
        <Autocomplete
            id="combo-box-demo"
            // @ts-ignore
            options={options}
                       onChange={onFilter}
            getOptionLabel={(option: any) => option?.id}
            style={{ width: 300, marginBottom: 15 }}
            renderInput={(params) => <TextField {...params} label="filter by tag" />}
        />
    )
}


export default TagFilter;
