import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useClipboard } from 'use-clipboard-copy';
import { useGistQuery } from '../../generated/graphql';
import ClipboardIcon from '../ClipboardIcon/ClipboardIcon';
import Loading from '../Loading/Loading';
import styles from './GistDetails.module.scss';
import useDocumentTitle from '@rehooks/document-title';

const CodeBlock = ({ language, code }: { language: any, code: any }) => {

    const clipboard = useClipboard({ copiedTimeout: 600 });

    const handleClick = React.useCallback(
        () => {
            clipboard.copy(code);
        },
        [clipboard, code]
    );

    if (!language || !code) {
        return null;
    }

    return (
        <>
            <SyntaxHighlighter language={language.name} style={docco}>
                {code}
            </SyntaxHighlighter>
            <Grid container direction="row" justify="space-between" alignItems="center">
                <small>written in {language.name}</small>
                <div>
                    <small className={styles.Copied}>
                        {clipboard.copied ? 'Copied' : ''}
                    </small>
                    <Button onClick={handleClick}>
                        <ClipboardIcon />
                    </Button>
                </div>
            </Grid>
        </>
    );
};

const GistDetailsContent = ({ gist }: { gist: any }) => {
    useDocumentTitle(gist?.name ? gist.name : 'Refman');
    return (
        <div>
            <p>{gist.description}</p>
            <CodeBlock language={gist.referenceLanguage} code={gist.code} />
        </div>
    );
};

const GistDetails = ({ id }: { id: any }) => {
    const { data } = useGistQuery({ variables: { id } })
    return data ? <GistDetailsContent gist={data.reference} /> : <Loading />;
};

export default GistDetails;
