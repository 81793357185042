import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  Upload: any;
};



export enum NoteState {
  Created = 'CREATED',
  Deleted = 'DELETED'
}

export enum NoteType {
  Fleeting = 'FLEETING'
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum ReferenceTypes {
  Document = 'DOCUMENT',
  Gist = 'GIST',
  Image = 'IMAGE',
  Link = 'LINK',
  Video = 'VIDEO'
}

export enum ReferenceStates {
  NotRead = 'NOT_READ',
  ReadLater = 'READ_LATER',
  Reading = 'READING',
  Read = 'READ'
}

export type CreateCommentInput = {
  reference?: Maybe<Scalars['ID']>;
  exploration?: Maybe<Scalars['ID']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Float']>;
};

export type CreateExplorationInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type CreateExplorationReferenceInput = {
  exploration: Scalars['ID'];
  reference: Scalars['ID'];
};

export type CreateExplorationTagInput = {
  exploration: Scalars['ID'];
  tag: Scalars['ID'];
};

export type CreateNoteInput = {
  content?: Maybe<Scalars['String']>;
  state?: Maybe<NoteState>;
  type?: Maybe<NoteType>;
};

export type CreateReferenceInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['Upload']>;
  type?: Maybe<ReferenceTypes>;
  code?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['ID']>;
  url?: Maybe<Scalars['String']>;
  remoteFile?: Maybe<Scalars['String']>;
};

export type CreateReferenceTagInput = {
  reference: Scalars['ID'];
  tag: Scalars['ID'];
};

export type CreateTagInput = {
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type OrderInput = {
  field: Scalars['String'];
  direction: OrderDirection;
};

export type RemoveCommentInput = {
  comment: Scalars['ID'];
};

export type RemoveExplorationTagInput = {
  exploration: Scalars['ID'];
  tag: Scalars['ID'];
};

export type RemoveNoteInput = {
  note: Scalars['ID'];
};

export type RemoveReferenceInput = {
  reference: Scalars['ID'];
};

export type RemoveReferenceTagInput = {
  reference: Scalars['ID'];
  tag: Scalars['ID'];
};

export type UpdateCommentInput = {
  comment: Scalars['ID'];
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  collapsed?: Maybe<Scalars['Boolean']>;
};

export type UpdateExplorationInput = {
  exploration: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UpdateExplorationTagsInput = {
  exploration: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateNoteInput = {
  note: Scalars['ID'];
  state?: Maybe<NoteState>;
  type?: Maybe<NoteType>;
  content?: Maybe<Scalars['String']>;
};

export type UpdateReferenceInput = {
  reference: Scalars['ID'];
  prayers?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type UpdateReferenceTagsInput = {
  reference: Scalars['ID'];
  tags?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  collapsed?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  reference?: Maybe<Reference>;
  exploration?: Maybe<Exploration>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Comments = {
  __typename?: 'Comments';
  results?: Maybe<Array<Maybe<Comment>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ExplorationReference = {
  __typename?: 'ExplorationReference';
  id: Scalars['ID'];
  exploration?: Maybe<Exploration>;
  reference?: Maybe<Reference>;
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ExplorationTag = {
  __typename?: 'ExplorationTag';
  id: Scalars['ID'];
  exploration?: Maybe<Exploration>;
  tag?: Maybe<Tag>;
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Exploration = {
  __typename?: 'Exploration';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  explorationReferences?: Maybe<Array<Maybe<ExplorationReference>>>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  explorationTags?: Maybe<Array<Maybe<ExplorationTag>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Explorations = {
  __typename?: 'Explorations';
  results?: Maybe<Array<Maybe<Exploration>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  content?: Maybe<Scalars['String']>;
  type?: Maybe<NoteType>;
  state?: Maybe<NoteState>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Notes = {
  __typename?: 'Notes';
  results?: Maybe<Array<Maybe<Note>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ReferenceTag = {
  __typename?: 'ReferenceTag';
  id?: Maybe<Scalars['ID']>;
  reference?: Maybe<Reference>;
  tag?: Maybe<Tag>;
};

export type Reference = {
  __typename?: 'Reference';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  prayers?: Maybe<Scalars['Int']>;
  thumbnail?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  remoteFile?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  referenceLanguage?: Maybe<ReferenceLanguage>;
  referenceTags?: Maybe<Array<Maybe<ReferenceTag>>>;
  comments?: Maybe<Array<Maybe<Comment>>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type References = {
  __typename?: 'References';
  results?: Maybe<Array<Maybe<Reference>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type ReferenceLanguage = {
  __typename?: 'ReferenceLanguage';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type ReferenceLanguages = {
  __typename?: 'ReferenceLanguages';
  results?: Maybe<Array<Maybe<ReferenceLanguage>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Tag = {
  __typename?: 'Tag';
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  referencesCount?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type Tags = {
  __typename?: 'Tags';
  results?: Maybe<Array<Maybe<Tag>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
};

export type Users = {
  __typename?: 'Users';
  results?: Maybe<Array<Maybe<User>>>;
  count?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Error = {
  __typename?: 'Error';
  field?: Maybe<Scalars['String']>;
  message?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  user?: Maybe<User>;
  token?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateCommentPayload = {
  __typename?: 'CreateCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateExplorationPayload = {
  __typename?: 'CreateExplorationPayload';
  exploration?: Maybe<Exploration>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateExplorationReferencePayload = {
  __typename?: 'CreateExplorationReferencePayload';
  explorationReference?: Maybe<ExplorationReference>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateExplorationTagPayload = {
  __typename?: 'CreateExplorationTagPayload';
  exploration?: Maybe<Exploration>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  note?: Maybe<Note>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateReferencePayload = {
  __typename?: 'CreateReferencePayload';
  reference?: Maybe<Reference>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateReferenceTagPayload = {
  __typename?: 'CreateReferenceTagPayload';
  referenceTag?: Maybe<ReferenceTag>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  tag?: Maybe<Tag>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveCommentPayload = {
  __typename?: 'RemoveCommentPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveExplorationTagPayload = {
  __typename?: 'RemoveExplorationTagPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveNotePayload = {
  __typename?: 'RemoveNotePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveReferencePayload = {
  __typename?: 'RemoveReferencePayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type RemoveReferenceTagPayload = {
  __typename?: 'RemoveReferenceTagPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateExplorationPayload = {
  __typename?: 'UpdateExplorationPayload';
  exploration?: Maybe<Exploration>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateExplorationTagsPayload = {
  __typename?: 'UpdateExplorationTagsPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateNotePayload = {
  __typename?: 'UpdateNotePayload';
  note?: Maybe<Note>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateReferencePayload = {
  __typename?: 'UpdateReferencePayload';
  reference?: Maybe<Reference>;
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type UpdateReferenceTagsPayload = {
  __typename?: 'UpdateReferenceTagsPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
};

export type Query = {
  __typename?: 'Query';
  comments?: Maybe<Comments>;
  exploration?: Maybe<Exploration>;
  explorations?: Maybe<Explorations>;
  notes?: Maybe<Notes>;
  reference?: Maybe<Reference>;
  references?: Maybe<References>;
  referenceLanguages?: Maybe<ReferenceLanguages>;
  status?: Maybe<Scalars['String']>;
  tags?: Maybe<Tags>;
  users?: Maybe<Users>;
};


export type QueryCommentsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['ID']>;
  exploration?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryExplorationArgs = {
  id: Scalars['ID'];
};


export type QueryExplorationsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
};


export type QueryNotesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryReferenceArgs = {
  id: Scalars['ID'];
};


export type QueryReferencesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
  language?: Maybe<Scalars['ID']>;
  type?: Maybe<Array<Maybe<ReferenceTypes>>>;
};


export type QueryReferenceLanguagesArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};


export type QueryTagsArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryUsersArgs = {
  pageSize?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createComment?: Maybe<CreateCommentPayload>;
  createExploration?: Maybe<CreateExplorationPayload>;
  createExplorationTag?: Maybe<CreateExplorationTagPayload>;
  createExplorationReference?: Maybe<CreateExplorationReferencePayload>;
  createNote?: Maybe<CreateNotePayload>;
  createReference?: Maybe<CreateReferencePayload>;
  createReferenceTag?: Maybe<CreateReferenceTagPayload>;
  createTag?: Maybe<CreateTagPayload>;
  login?: Maybe<AuthPayload>;
  removeComment?: Maybe<RemoveCommentPayload>;
  removeExplorationTag?: Maybe<RemoveExplorationTagPayload>;
  removeNote?: Maybe<RemoveNotePayload>;
  removeReference?: Maybe<RemoveReferencePayload>;
  removeReferenceTag?: Maybe<RemoveReferenceTagPayload>;
  updateComment?: Maybe<UpdateCommentPayload>;
  updateExploration?: Maybe<UpdateExplorationPayload>;
  updateExplorationTags?: Maybe<UpdateExplorationTagsPayload>;
  updateNote?: Maybe<UpdateNotePayload>;
  updateReference?: Maybe<UpdateReferencePayload>;
  updateReferenceTags?: Maybe<UpdateReferenceTagsPayload>;
};


export type MutationCreateCommentArgs = {
  data?: Maybe<CreateCommentInput>;
};


export type MutationCreateExplorationArgs = {
  data?: Maybe<CreateExplorationInput>;
};


export type MutationCreateExplorationTagArgs = {
  data?: Maybe<CreateExplorationTagInput>;
};


export type MutationCreateExplorationReferenceArgs = {
  data?: Maybe<CreateExplorationReferenceInput>;
};


export type MutationCreateNoteArgs = {
  data?: Maybe<CreateNoteInput>;
};


export type MutationCreateReferenceArgs = {
  data?: Maybe<CreateReferenceInput>;
};


export type MutationCreateReferenceTagArgs = {
  data?: Maybe<CreateReferenceTagInput>;
};


export type MutationCreateTagArgs = {
  data?: Maybe<CreateTagInput>;
};


export type MutationLoginArgs = {
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};


export type MutationRemoveCommentArgs = {
  data?: Maybe<RemoveCommentInput>;
};


export type MutationRemoveExplorationTagArgs = {
  data?: Maybe<RemoveExplorationTagInput>;
};


export type MutationRemoveNoteArgs = {
  data?: Maybe<RemoveNoteInput>;
};


export type MutationRemoveReferenceArgs = {
  data?: Maybe<RemoveReferenceInput>;
};


export type MutationRemoveReferenceTagArgs = {
  data?: Maybe<RemoveReferenceTagInput>;
};


export type MutationUpdateCommentArgs = {
  data?: Maybe<UpdateCommentInput>;
};


export type MutationUpdateExplorationArgs = {
  data?: Maybe<UpdateExplorationInput>;
};


export type MutationUpdateExplorationTagsArgs = {
  data?: Maybe<UpdateExplorationTagsInput>;
};


export type MutationUpdateNoteArgs = {
  data?: Maybe<UpdateNoteInput>;
};


export type MutationUpdateReferenceArgs = {
  data?: Maybe<UpdateReferenceInput>;
};


export type MutationUpdateReferenceTagsArgs = {
  data?: Maybe<UpdateReferenceTagsInput>;
};

export type CommentAttributesFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'description' | 'x' | 'y' | 'time' | 'createdAt' | 'color' | 'collapsed'>
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type DocumentAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'type' | 'remoteFile' | 'state' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>>, referenceTags?: Maybe<Array<Maybe<(
    { __typename?: 'ReferenceTag' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>> }
);

export type ErrorAttributesFragment = (
  { __typename?: 'Error' }
  & Pick<Error, 'field' | 'message'>
);

export type ExplorationAttributesFragment = (
  { __typename?: 'Exploration' }
  & Pick<Exploration, 'id' | 'name' | 'description' | 'summary' | 'state' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>>, explorationTags?: Maybe<Array<Maybe<(
    { __typename?: 'ExplorationTag' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>>, explorationReferences?: Maybe<Array<Maybe<(
    { __typename?: 'ExplorationReference' }
    & { reference?: Maybe<(
      { __typename?: 'Reference' }
      & Pick<Reference, 'id' | 'name' | 'type'>
    )> }
  )>>> }
);

export type GistAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'type' | 'code' | 'createdAt' | 'updatedAt'>
  & { referenceLanguage?: Maybe<(
    { __typename?: 'ReferenceLanguage' }
    & Pick<ReferenceLanguage, 'id' | 'name'>
  )> }
);

export type GraphicAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'file' | 'thumbnail' | 'prayers' | 'type' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>>, referenceTags?: Maybe<Array<Maybe<(
    { __typename?: 'ReferenceTag' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>> }
);

export type LinkAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'type' | 'url' | 'state' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>>, referenceTags?: Maybe<Array<Maybe<(
    { __typename?: 'ReferenceTag' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>> }
);

export type ReferenceAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'file' | 'thumbnail' | 'prayers' | 'type' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>>, referenceTags?: Maybe<Array<Maybe<(
    { __typename?: 'ReferenceTag' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id'>
    )> }
  )>>> }
);

export type TagAttributesFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'description' | 'referencesCount' | 'createdAt' | 'updatedAt'>
);

export type VideoAttributesFragment = (
  { __typename?: 'Reference' }
  & Pick<Reference, 'id' | 'name' | 'description' | 'file' | 'thumbnail' | 'prayers' | 'type' | 'createdAt' | 'updatedAt'>
  & { comments?: Maybe<Array<Maybe<(
    { __typename?: 'Comment' }
    & CommentAttributesFragment
  )>>> }
);

export type ExplorationsQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
}>;


export type ExplorationsQuery = (
  { __typename?: 'Query' }
  & { explorations?: Maybe<(
    { __typename?: 'Explorations' }
    & Pick<Explorations, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Exploration' }
      & ExplorationAttributesFragment
    )>>> }
  )> }
);

export type ExplorationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExplorationQuery = (
  { __typename?: 'Query' }
  & { exploration?: Maybe<(
    { __typename?: 'Exploration' }
    & ExplorationAttributesFragment
  )> }
);

export type ReferencesQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
}>;


export type ReferencesQuery = (
  { __typename?: 'Query' }
  & { references?: Maybe<(
    { __typename?: 'References' }
    & Pick<References, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Reference' }
      & ReferenceAttributesFragment
    )>>> }
  )> }
);

export type ReferenceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReferenceQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & Pick<Reference, 'id' | 'name' | 'description' | 'file'>
  )> }
);

export type ReferenceLanguagesQueryVariables = Exact<{ [key: string]: never; }>;


export type ReferenceLanguagesQuery = (
  { __typename?: 'Query' }
  & { referenceLanguages?: Maybe<(
    { __typename?: 'ReferenceLanguages' }
    & Pick<ReferenceLanguages, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'ReferenceLanguage' }
      & Pick<ReferenceLanguage, 'id' | 'name' | 'createdAt' | 'updatedAt'>
    )>>> }
  )> }
);

export type GistsQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['ID']>;
}>;


export type GistsQuery = (
  { __typename?: 'Query' }
  & { references?: Maybe<(
    { __typename?: 'References' }
    & Pick<References, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Reference' }
      & GistAttributesFragment
    )>>> }
  )> }
);

export type GistQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GistQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & GistAttributesFragment
  )> }
);

export type GraphicsQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
}>;


export type GraphicsQuery = (
  { __typename?: 'Query' }
  & { references?: Maybe<(
    { __typename?: 'References' }
    & Pick<References, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Reference' }
      & ReferenceAttributesFragment
    )>>> }
  )> }
);

export type GraphicQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GraphicQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & GraphicAttributesFragment
  )> }
);

export type LinksQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
}>;


export type LinksQuery = (
  { __typename?: 'Query' }
  & { references?: Maybe<(
    { __typename?: 'References' }
    & Pick<References, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Reference' }
      & LinkAttributesFragment
    )>>> }
  )> }
);

export type LinkQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LinkQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & LinkAttributesFragment
  )> }
);

export type TagsQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags?: Maybe<(
    { __typename?: 'Tags' }
    & Pick<Tags, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'referencesCount' | 'description'>
    )>>> }
  )> }
);

export type VideoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VideoQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & VideoAttributesFragment
  )> }
);

export type CommentsQueryVariables = Exact<{
  search?: Maybe<Scalars['String']>;
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments?: Maybe<(
    { __typename?: 'Comments' }
    & Pick<Comments, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'description'>
      & { exploration?: Maybe<(
        { __typename?: 'Exploration' }
        & Pick<Exploration, 'id' | 'name'>
      )>, reference?: Maybe<(
        { __typename?: 'Reference' }
        & Pick<Reference, 'id' | 'name'>
      )> }
    )>>> }
  )> }
);

export type DocumentsQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
  tag?: Maybe<Scalars['ID']>;
}>;


export type DocumentsQuery = (
  { __typename?: 'Query' }
  & { references?: Maybe<(
    { __typename?: 'References' }
    & Pick<References, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Reference' }
      & DocumentAttributesFragment
    )>>> }
  )> }
);

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentQuery = (
  { __typename?: 'Query' }
  & { reference?: Maybe<(
    { __typename?: 'Reference' }
    & DocumentAttributesFragment
  )> }
);

export type FleetingNotesQueryVariables = Exact<{
  page: Scalars['Int'];
  search?: Maybe<Scalars['String']>;
}>;


export type FleetingNotesQuery = (
  { __typename?: 'Query' }
  & { comments?: Maybe<(
    { __typename?: 'Comments' }
    & Pick<Comments, 'count'>
    & { results?: Maybe<Array<Maybe<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'description'>
    )>>> }
  )> }
);

export type CreateCommentMutationVariables = Exact<{
  data?: Maybe<CreateCommentInput>;
}>;


export type CreateCommentMutation = (
  { __typename?: 'Mutation' }
  & { createComment?: Maybe<(
    { __typename?: 'CreateCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & CommentAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateExplorationMutationVariables = Exact<{
  data?: Maybe<CreateExplorationInput>;
}>;


export type CreateExplorationMutation = (
  { __typename?: 'Mutation' }
  & { createExploration?: Maybe<(
    { __typename?: 'CreateExplorationPayload' }
    & { exploration?: Maybe<(
      { __typename?: 'Exploration' }
      & ExplorationAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateExplorationReferenceMutationVariables = Exact<{
  data?: Maybe<CreateExplorationReferenceInput>;
}>;


export type CreateExplorationReferenceMutation = (
  { __typename?: 'Mutation' }
  & { createExplorationReference?: Maybe<(
    { __typename?: 'CreateExplorationReferencePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateExplorationTagMutationVariables = Exact<{
  data?: Maybe<CreateExplorationTagInput>;
}>;


export type CreateExplorationTagMutation = (
  { __typename?: 'Mutation' }
  & { createExplorationTag?: Maybe<(
    { __typename?: 'CreateExplorationTagPayload' }
    & { exploration?: Maybe<(
      { __typename?: 'Exploration' }
      & ExplorationAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateReferenceMutationVariables = Exact<{
  data?: Maybe<CreateReferenceInput>;
}>;


export type CreateReferenceMutation = (
  { __typename?: 'Mutation' }
  & { createReference?: Maybe<(
    { __typename?: 'CreateReferencePayload' }
    & { reference?: Maybe<(
      { __typename?: 'Reference' }
      & ReferenceAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateReferenceTagMutationVariables = Exact<{
  data?: Maybe<CreateReferenceTagInput>;
}>;


export type CreateReferenceTagMutation = (
  { __typename?: 'Mutation' }
  & { createReferenceTag?: Maybe<(
    { __typename?: 'CreateReferenceTagPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type CreateTagMutationVariables = Exact<{
  data?: Maybe<CreateTagInput>;
}>;


export type CreateTagMutation = (
  { __typename?: 'Mutation' }
  & { createTag?: Maybe<(
    { __typename?: 'CreateTagPayload' }
    & { tag?: Maybe<(
      { __typename?: 'Tag' }
      & TagAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & Pick<Error, 'field' | 'message'>
    )>>> }
  )> }
);

export type RemoveCommentMutationVariables = Exact<{
  data?: Maybe<RemoveCommentInput>;
}>;


export type RemoveCommentMutation = (
  { __typename?: 'Mutation' }
  & { removeComment?: Maybe<(
    { __typename?: 'RemoveCommentPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type RemoveExplorationTagMutationVariables = Exact<{
  data?: Maybe<RemoveExplorationTagInput>;
}>;


export type RemoveExplorationTagMutation = (
  { __typename?: 'Mutation' }
  & { removeExplorationTag?: Maybe<(
    { __typename?: 'RemoveExplorationTagPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type RemoveReferenceTagMutationVariables = Exact<{
  data?: Maybe<RemoveReferenceTagInput>;
}>;


export type RemoveReferenceTagMutation = (
  { __typename?: 'Mutation' }
  & { removeReferenceTag?: Maybe<(
    { __typename?: 'RemoveReferenceTagPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type RemoveNoteMutationVariables = Exact<{
  data?: Maybe<RemoveNoteInput>;
}>;


export type RemoveNoteMutation = (
  { __typename?: 'Mutation' }
  & { removeNote?: Maybe<(
    { __typename?: 'RemoveNotePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type RemoveReferenceMutationVariables = Exact<{
  data?: Maybe<RemoveReferenceInput>;
}>;


export type RemoveReferenceMutation = (
  { __typename?: 'Mutation' }
  & { removeReference?: Maybe<(
    { __typename?: 'RemoveReferencePayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type UpdateCommentMutationVariables = Exact<{
  data?: Maybe<UpdateCommentInput>;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment?: Maybe<(
    { __typename?: 'UpdateCommentPayload' }
    & { comment?: Maybe<(
      { __typename?: 'Comment' }
      & CommentAttributesFragment
    )> }
  )> }
);

export type UpdateExplorationMutationVariables = Exact<{
  data?: Maybe<UpdateExplorationInput>;
}>;


export type UpdateExplorationMutation = (
  { __typename?: 'Mutation' }
  & { updateExploration?: Maybe<(
    { __typename?: 'UpdateExplorationPayload' }
    & { exploration?: Maybe<(
      { __typename?: 'Exploration' }
      & ExplorationAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type UpdateExplorationTagsMutationVariables = Exact<{
  data?: Maybe<UpdateExplorationTagsInput>;
}>;


export type UpdateExplorationTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateExplorationTags?: Maybe<(
    { __typename?: 'UpdateExplorationTagsPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type UpdateReferenceMutationVariables = Exact<{
  data?: Maybe<UpdateReferenceInput>;
}>;


export type UpdateReferenceMutation = (
  { __typename?: 'Mutation' }
  & { updateReference?: Maybe<(
    { __typename?: 'UpdateReferencePayload' }
    & { reference?: Maybe<(
      { __typename?: 'Reference' }
      & ReferenceAttributesFragment
    )>, errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export type UpdateReferenceTagsMutationVariables = Exact<{
  data?: Maybe<UpdateReferenceTagsInput>;
}>;


export type UpdateReferenceTagsMutation = (
  { __typename?: 'Mutation' }
  & { updateReferenceTags?: Maybe<(
    { __typename?: 'UpdateReferenceTagsPayload' }
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'Error' }
      & ErrorAttributesFragment
    )>>> }
  )> }
);

export const CommentAttributesFragmentDoc = gql`
    fragment commentAttributes on Comment {
  id
  description
  x
  y
  time
  user {
    name
  }
  createdAt
  color
  collapsed
}
    `;
export const DocumentAttributesFragmentDoc = gql`
    fragment documentAttributes on Reference {
  id
  name
  description
  type
  remoteFile
  state
  comments {
    ...commentAttributes
  }
  referenceTags {
    tag {
      id
    }
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const ErrorAttributesFragmentDoc = gql`
    fragment errorAttributes on Error {
  field
  message
}
    `;
export const ExplorationAttributesFragmentDoc = gql`
    fragment explorationAttributes on Exploration {
  id
  name
  description
  summary
  state
  comments {
    ...commentAttributes
  }
  explorationTags {
    tag {
      id
    }
  }
  explorationReferences {
    reference {
      id
      name
      type
    }
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const GistAttributesFragmentDoc = gql`
    fragment gistAttributes on Reference {
  id
  name
  description
  type
  code
  referenceLanguage {
    id
    name
  }
  createdAt
  updatedAt
}
    `;
export const GraphicAttributesFragmentDoc = gql`
    fragment graphicAttributes on Reference {
  id
  name
  description
  file
  thumbnail
  prayers
  type
  comments {
    ...commentAttributes
  }
  referenceTags {
    tag {
      id
    }
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const LinkAttributesFragmentDoc = gql`
    fragment linkAttributes on Reference {
  id
  name
  description
  type
  url
  state
  comments {
    ...commentAttributes
  }
  referenceTags {
    tag {
      id
    }
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const ReferenceAttributesFragmentDoc = gql`
    fragment referenceAttributes on Reference {
  id
  name
  description
  file
  thumbnail
  prayers
  type
  comments {
    ...commentAttributes
  }
  referenceTags {
    tag {
      id
    }
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const TagAttributesFragmentDoc = gql`
    fragment tagAttributes on Tag {
  id
  description
  referencesCount
  createdAt
  updatedAt
}
    `;
export const VideoAttributesFragmentDoc = gql`
    fragment videoAttributes on Reference {
  id
  name
  description
  file
  thumbnail
  prayers
  type
  comments {
    ...commentAttributes
  }
  createdAt
  updatedAt
}
    ${CommentAttributesFragmentDoc}`;
export const ExplorationsDocument = gql`
    query explorations($page: Int!, $search: String, $tag: ID) {
  explorations(page: $page, search: $search, tag: $tag) {
    count
    results {
      ...explorationAttributes
    }
  }
}
    ${ExplorationAttributesFragmentDoc}`;

/**
 * __useExplorationsQuery__
 *
 * To run a query within a React component, call `useExplorationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useExplorationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExplorationsQuery, ExplorationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExplorationsQuery, ExplorationsQueryVariables>(ExplorationsDocument, baseOptions);
      }
export function useExplorationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExplorationsQuery, ExplorationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExplorationsQuery, ExplorationsQueryVariables>(ExplorationsDocument, baseOptions);
        }
export type ExplorationsQueryHookResult = ReturnType<typeof useExplorationsQuery>;
export type ExplorationsLazyQueryHookResult = ReturnType<typeof useExplorationsLazyQuery>;
export type ExplorationsQueryResult = ApolloReactCommon.QueryResult<ExplorationsQuery, ExplorationsQueryVariables>;
export const ExplorationDocument = gql`
    query exploration($id: ID!) {
  exploration(id: $id) {
    ...explorationAttributes
  }
}
    ${ExplorationAttributesFragmentDoc}`;

/**
 * __useExplorationQuery__
 *
 * To run a query within a React component, call `useExplorationQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplorationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplorationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExplorationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExplorationQuery, ExplorationQueryVariables>) {
        return ApolloReactHooks.useQuery<ExplorationQuery, ExplorationQueryVariables>(ExplorationDocument, baseOptions);
      }
export function useExplorationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExplorationQuery, ExplorationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExplorationQuery, ExplorationQueryVariables>(ExplorationDocument, baseOptions);
        }
export type ExplorationQueryHookResult = ReturnType<typeof useExplorationQuery>;
export type ExplorationLazyQueryHookResult = ReturnType<typeof useExplorationLazyQuery>;
export type ExplorationQueryResult = ApolloReactCommon.QueryResult<ExplorationQuery, ExplorationQueryVariables>;
export const ReferencesDocument = gql`
    query references($page: Int!, $search: String) {
  references(page: $page, search: $search) {
    count
    results {
      ...referenceAttributes
    }
  }
}
    ${ReferenceAttributesFragmentDoc}`;

/**
 * __useReferencesQuery__
 *
 * To run a query within a React component, call `useReferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferencesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useReferencesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferencesQuery, ReferencesQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferencesQuery, ReferencesQueryVariables>(ReferencesDocument, baseOptions);
      }
export function useReferencesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferencesQuery, ReferencesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferencesQuery, ReferencesQueryVariables>(ReferencesDocument, baseOptions);
        }
export type ReferencesQueryHookResult = ReturnType<typeof useReferencesQuery>;
export type ReferencesLazyQueryHookResult = ReturnType<typeof useReferencesLazyQuery>;
export type ReferencesQueryResult = ApolloReactCommon.QueryResult<ReferencesQuery, ReferencesQueryVariables>;
export const ReferenceDocument = gql`
    query reference($id: ID!) {
  reference(id: $id) {
    id
    name
    description
    file
  }
}
    `;

/**
 * __useReferenceQuery__
 *
 * To run a query within a React component, call `useReferenceQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReferenceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferenceQuery, ReferenceQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferenceQuery, ReferenceQueryVariables>(ReferenceDocument, baseOptions);
      }
export function useReferenceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferenceQuery, ReferenceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferenceQuery, ReferenceQueryVariables>(ReferenceDocument, baseOptions);
        }
export type ReferenceQueryHookResult = ReturnType<typeof useReferenceQuery>;
export type ReferenceLazyQueryHookResult = ReturnType<typeof useReferenceLazyQuery>;
export type ReferenceQueryResult = ApolloReactCommon.QueryResult<ReferenceQuery, ReferenceQueryVariables>;
export const ReferenceLanguagesDocument = gql`
    query referenceLanguages {
  referenceLanguages {
    count
    results {
      id
      name
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useReferenceLanguagesQuery__
 *
 * To run a query within a React component, call `useReferenceLanguagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferenceLanguagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferenceLanguagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReferenceLanguagesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReferenceLanguagesQuery, ReferenceLanguagesQueryVariables>) {
        return ApolloReactHooks.useQuery<ReferenceLanguagesQuery, ReferenceLanguagesQueryVariables>(ReferenceLanguagesDocument, baseOptions);
      }
export function useReferenceLanguagesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReferenceLanguagesQuery, ReferenceLanguagesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReferenceLanguagesQuery, ReferenceLanguagesQueryVariables>(ReferenceLanguagesDocument, baseOptions);
        }
export type ReferenceLanguagesQueryHookResult = ReturnType<typeof useReferenceLanguagesQuery>;
export type ReferenceLanguagesLazyQueryHookResult = ReturnType<typeof useReferenceLanguagesLazyQuery>;
export type ReferenceLanguagesQueryResult = ApolloReactCommon.QueryResult<ReferenceLanguagesQuery, ReferenceLanguagesQueryVariables>;
export const GistsDocument = gql`
    query gists($page: Int!, $search: String, $language: ID) {
  references(page: $page, search: $search, type: [GIST], language: $language) {
    count
    results {
      ...gistAttributes
    }
  }
}
    ${GistAttributesFragmentDoc}`;

/**
 * __useGistsQuery__
 *
 * To run a query within a React component, call `useGistsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGistsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGistsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGistsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GistsQuery, GistsQueryVariables>) {
        return ApolloReactHooks.useQuery<GistsQuery, GistsQueryVariables>(GistsDocument, baseOptions);
      }
export function useGistsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GistsQuery, GistsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GistsQuery, GistsQueryVariables>(GistsDocument, baseOptions);
        }
export type GistsQueryHookResult = ReturnType<typeof useGistsQuery>;
export type GistsLazyQueryHookResult = ReturnType<typeof useGistsLazyQuery>;
export type GistsQueryResult = ApolloReactCommon.QueryResult<GistsQuery, GistsQueryVariables>;
export const GistDocument = gql`
    query gist($id: ID!) {
  reference(id: $id) {
    ...gistAttributes
  }
}
    ${GistAttributesFragmentDoc}`;

/**
 * __useGistQuery__
 *
 * To run a query within a React component, call `useGistQuery` and pass it any options that fit your needs.
 * When your component renders, `useGistQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGistQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGistQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GistQuery, GistQueryVariables>) {
        return ApolloReactHooks.useQuery<GistQuery, GistQueryVariables>(GistDocument, baseOptions);
      }
export function useGistLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GistQuery, GistQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GistQuery, GistQueryVariables>(GistDocument, baseOptions);
        }
export type GistQueryHookResult = ReturnType<typeof useGistQuery>;
export type GistLazyQueryHookResult = ReturnType<typeof useGistLazyQuery>;
export type GistQueryResult = ApolloReactCommon.QueryResult<GistQuery, GistQueryVariables>;
export const GraphicsDocument = gql`
    query graphics($page: Int!, $search: String, $tag: ID) {
  references(page: $page, search: $search, tag: $tag, type: [IMAGE, VIDEO]) {
    count
    results {
      ...referenceAttributes
    }
  }
}
    ${ReferenceAttributesFragmentDoc}`;

/**
 * __useGraphicsQuery__
 *
 * To run a query within a React component, call `useGraphicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphicsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useGraphicsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GraphicsQuery, GraphicsQueryVariables>) {
        return ApolloReactHooks.useQuery<GraphicsQuery, GraphicsQueryVariables>(GraphicsDocument, baseOptions);
      }
export function useGraphicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GraphicsQuery, GraphicsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GraphicsQuery, GraphicsQueryVariables>(GraphicsDocument, baseOptions);
        }
export type GraphicsQueryHookResult = ReturnType<typeof useGraphicsQuery>;
export type GraphicsLazyQueryHookResult = ReturnType<typeof useGraphicsLazyQuery>;
export type GraphicsQueryResult = ApolloReactCommon.QueryResult<GraphicsQuery, GraphicsQueryVariables>;
export const GraphicDocument = gql`
    query graphic($id: ID!) {
  reference(id: $id) {
    ...graphicAttributes
  }
}
    ${GraphicAttributesFragmentDoc}`;

/**
 * __useGraphicQuery__
 *
 * To run a query within a React component, call `useGraphicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGraphicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGraphicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGraphicQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GraphicQuery, GraphicQueryVariables>) {
        return ApolloReactHooks.useQuery<GraphicQuery, GraphicQueryVariables>(GraphicDocument, baseOptions);
      }
export function useGraphicLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GraphicQuery, GraphicQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GraphicQuery, GraphicQueryVariables>(GraphicDocument, baseOptions);
        }
export type GraphicQueryHookResult = ReturnType<typeof useGraphicQuery>;
export type GraphicLazyQueryHookResult = ReturnType<typeof useGraphicLazyQuery>;
export type GraphicQueryResult = ApolloReactCommon.QueryResult<GraphicQuery, GraphicQueryVariables>;
export const LinksDocument = gql`
    query links($page: Int!, $search: String, $tag: ID) {
  references(page: $page, search: $search, type: [LINK], tag: $tag) {
    count
    results {
      ...linkAttributes
    }
  }
}
    ${LinkAttributesFragmentDoc}`;

/**
 * __useLinksQuery__
 *
 * To run a query within a React component, call `useLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinksQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useLinksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LinksQuery, LinksQueryVariables>) {
        return ApolloReactHooks.useQuery<LinksQuery, LinksQueryVariables>(LinksDocument, baseOptions);
      }
export function useLinksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinksQuery, LinksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LinksQuery, LinksQueryVariables>(LinksDocument, baseOptions);
        }
export type LinksQueryHookResult = ReturnType<typeof useLinksQuery>;
export type LinksLazyQueryHookResult = ReturnType<typeof useLinksLazyQuery>;
export type LinksQueryResult = ApolloReactCommon.QueryResult<LinksQuery, LinksQueryVariables>;
export const LinkDocument = gql`
    query link($id: ID!) {
  reference(id: $id) {
    ...linkAttributes
  }
}
    ${LinkAttributesFragmentDoc}`;

/**
 * __useLinkQuery__
 *
 * To run a query within a React component, call `useLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLinkQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LinkQuery, LinkQueryVariables>) {
        return ApolloReactHooks.useQuery<LinkQuery, LinkQueryVariables>(LinkDocument, baseOptions);
      }
export function useLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LinkQuery, LinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LinkQuery, LinkQueryVariables>(LinkDocument, baseOptions);
        }
export type LinkQueryHookResult = ReturnType<typeof useLinkQuery>;
export type LinkLazyQueryHookResult = ReturnType<typeof useLinkLazyQuery>;
export type LinkQueryResult = ApolloReactCommon.QueryResult<LinkQuery, LinkQueryVariables>;
export const TagsDocument = gql`
    query tags($page: Int, $pageSize: Int, $search: String) {
  tags(page: $page, pageSize: $pageSize, search: $search) {
    count
    results {
      id
      referencesCount
      description
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      pageSize: // value for 'pageSize'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        return ApolloReactHooks.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
      }
export function useTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, baseOptions);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = ApolloReactCommon.QueryResult<TagsQuery, TagsQueryVariables>;
export const VideoDocument = gql`
    query video($id: ID!) {
  reference(id: $id) {
    ...videoAttributes
  }
}
    ${VideoAttributesFragmentDoc}`;

/**
 * __useVideoQuery__
 *
 * To run a query within a React component, call `useVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVideoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VideoQuery, VideoQueryVariables>) {
        return ApolloReactHooks.useQuery<VideoQuery, VideoQueryVariables>(VideoDocument, baseOptions);
      }
export function useVideoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VideoQuery, VideoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VideoQuery, VideoQueryVariables>(VideoDocument, baseOptions);
        }
export type VideoQueryHookResult = ReturnType<typeof useVideoQuery>;
export type VideoLazyQueryHookResult = ReturnType<typeof useVideoLazyQuery>;
export type VideoQueryResult = ApolloReactCommon.QueryResult<VideoQuery, VideoQueryVariables>;
export const CommentsDocument = gql`
    query comments($search: String) {
  comments(search: $search, pageSize: 9999, page: 1) {
    count
    results {
      id
      description
      exploration {
        id
        name
      }
      reference {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
      }
export function useCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = ApolloReactCommon.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const DocumentsDocument = gql`
    query documents($page: Int!, $search: String, $tag: ID) {
  references(page: $page, type: [DOCUMENT], search: $search, tag: $tag) {
    count
    results {
      ...documentAttributes
    }
  }
}
    ${DocumentAttributesFragmentDoc}`;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *      tag: // value for 'tag'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
      }
export function useDocumentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, baseOptions);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = ApolloReactCommon.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const DocumentDocument = gql`
    query document($id: ID!) {
  reference(id: $id) {
    ...documentAttributes
  }
}
    ${DocumentAttributesFragmentDoc}`;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, baseOptions);
      }
export function useDocumentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, baseOptions);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = ApolloReactCommon.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const FleetingNotesDocument = gql`
    query fleetingNotes($page: Int!, $search: String) {
  comments(page: $page, pageSize: 9999, reference: null, exploration: null, search: $search) {
    count
    results {
      id
      description
    }
  }
}
    `;

/**
 * __useFleetingNotesQuery__
 *
 * To run a query within a React component, call `useFleetingNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFleetingNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFleetingNotesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useFleetingNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FleetingNotesQuery, FleetingNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<FleetingNotesQuery, FleetingNotesQueryVariables>(FleetingNotesDocument, baseOptions);
      }
export function useFleetingNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FleetingNotesQuery, FleetingNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FleetingNotesQuery, FleetingNotesQueryVariables>(FleetingNotesDocument, baseOptions);
        }
export type FleetingNotesQueryHookResult = ReturnType<typeof useFleetingNotesQuery>;
export type FleetingNotesLazyQueryHookResult = ReturnType<typeof useFleetingNotesLazyQuery>;
export type FleetingNotesQueryResult = ApolloReactCommon.QueryResult<FleetingNotesQuery, FleetingNotesQueryVariables>;
export const CreateCommentDocument = gql`
    mutation createComment($data: CreateCommentInput) {
  createComment(data: $data) {
    comment {
      ...commentAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${CommentAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type CreateCommentMutationFn = ApolloReactCommon.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, baseOptions);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = ApolloReactCommon.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const CreateExplorationDocument = gql`
    mutation createExploration($data: CreateExplorationInput) {
  createExploration(data: $data) {
    exploration {
      ...explorationAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${ExplorationAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type CreateExplorationMutationFn = ApolloReactCommon.MutationFunction<CreateExplorationMutation, CreateExplorationMutationVariables>;

/**
 * __useCreateExplorationMutation__
 *
 * To run a mutation, you first call `useCreateExplorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplorationMutation, { data, loading, error }] = useCreateExplorationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExplorationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExplorationMutation, CreateExplorationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExplorationMutation, CreateExplorationMutationVariables>(CreateExplorationDocument, baseOptions);
      }
export type CreateExplorationMutationHookResult = ReturnType<typeof useCreateExplorationMutation>;
export type CreateExplorationMutationResult = ApolloReactCommon.MutationResult<CreateExplorationMutation>;
export type CreateExplorationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExplorationMutation, CreateExplorationMutationVariables>;
export const CreateExplorationReferenceDocument = gql`
    mutation createExplorationReference($data: CreateExplorationReferenceInput) {
  createExplorationReference(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type CreateExplorationReferenceMutationFn = ApolloReactCommon.MutationFunction<CreateExplorationReferenceMutation, CreateExplorationReferenceMutationVariables>;

/**
 * __useCreateExplorationReferenceMutation__
 *
 * To run a mutation, you first call `useCreateExplorationReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplorationReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplorationReferenceMutation, { data, loading, error }] = useCreateExplorationReferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExplorationReferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExplorationReferenceMutation, CreateExplorationReferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExplorationReferenceMutation, CreateExplorationReferenceMutationVariables>(CreateExplorationReferenceDocument, baseOptions);
      }
export type CreateExplorationReferenceMutationHookResult = ReturnType<typeof useCreateExplorationReferenceMutation>;
export type CreateExplorationReferenceMutationResult = ApolloReactCommon.MutationResult<CreateExplorationReferenceMutation>;
export type CreateExplorationReferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExplorationReferenceMutation, CreateExplorationReferenceMutationVariables>;
export const CreateExplorationTagDocument = gql`
    mutation createExplorationTag($data: CreateExplorationTagInput) {
  createExplorationTag(data: $data) {
    exploration {
      ...explorationAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${ExplorationAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type CreateExplorationTagMutationFn = ApolloReactCommon.MutationFunction<CreateExplorationTagMutation, CreateExplorationTagMutationVariables>;

/**
 * __useCreateExplorationTagMutation__
 *
 * To run a mutation, you first call `useCreateExplorationTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplorationTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplorationTagMutation, { data, loading, error }] = useCreateExplorationTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateExplorationTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExplorationTagMutation, CreateExplorationTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExplorationTagMutation, CreateExplorationTagMutationVariables>(CreateExplorationTagDocument, baseOptions);
      }
export type CreateExplorationTagMutationHookResult = ReturnType<typeof useCreateExplorationTagMutation>;
export type CreateExplorationTagMutationResult = ApolloReactCommon.MutationResult<CreateExplorationTagMutation>;
export type CreateExplorationTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExplorationTagMutation, CreateExplorationTagMutationVariables>;
export const CreateReferenceDocument = gql`
    mutation createReference($data: CreateReferenceInput) {
  createReference(data: $data) {
    reference {
      ...referenceAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${ReferenceAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type CreateReferenceMutationFn = ApolloReactCommon.MutationFunction<CreateReferenceMutation, CreateReferenceMutationVariables>;

/**
 * __useCreateReferenceMutation__
 *
 * To run a mutation, you first call `useCreateReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceMutation, { data, loading, error }] = useCreateReferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferenceMutation, CreateReferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferenceMutation, CreateReferenceMutationVariables>(CreateReferenceDocument, baseOptions);
      }
export type CreateReferenceMutationHookResult = ReturnType<typeof useCreateReferenceMutation>;
export type CreateReferenceMutationResult = ApolloReactCommon.MutationResult<CreateReferenceMutation>;
export type CreateReferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferenceMutation, CreateReferenceMutationVariables>;
export const CreateReferenceTagDocument = gql`
    mutation createReferenceTag($data: CreateReferenceTagInput) {
  createReferenceTag(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type CreateReferenceTagMutationFn = ApolloReactCommon.MutationFunction<CreateReferenceTagMutation, CreateReferenceTagMutationVariables>;

/**
 * __useCreateReferenceTagMutation__
 *
 * To run a mutation, you first call `useCreateReferenceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferenceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferenceTagMutation, { data, loading, error }] = useCreateReferenceTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateReferenceTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateReferenceTagMutation, CreateReferenceTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateReferenceTagMutation, CreateReferenceTagMutationVariables>(CreateReferenceTagDocument, baseOptions);
      }
export type CreateReferenceTagMutationHookResult = ReturnType<typeof useCreateReferenceTagMutation>;
export type CreateReferenceTagMutationResult = ApolloReactCommon.MutationResult<CreateReferenceTagMutation>;
export type CreateReferenceTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateReferenceTagMutation, CreateReferenceTagMutationVariables>;
export const CreateTagDocument = gql`
    mutation createTag($data: CreateTagInput) {
  createTag(data: $data) {
    tag {
      ...tagAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${TagAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type CreateTagMutationFn = ApolloReactCommon.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, baseOptions);
      }
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = ApolloReactCommon.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String, $password: String) {
  login(email: $email, password: $password) {
    user {
      id
      name
    }
    token
    errors {
      field
      message
    }
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const RemoveCommentDocument = gql`
    mutation removeComment($data: RemoveCommentInput) {
  removeComment(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type RemoveCommentMutationFn = ApolloReactCommon.MutationFunction<RemoveCommentMutation, RemoveCommentMutationVariables>;

/**
 * __useRemoveCommentMutation__
 *
 * To run a mutation, you first call `useRemoveCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCommentMutation, { data, loading, error }] = useRemoveCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveCommentMutation, RemoveCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveCommentMutation, RemoveCommentMutationVariables>(RemoveCommentDocument, baseOptions);
      }
export type RemoveCommentMutationHookResult = ReturnType<typeof useRemoveCommentMutation>;
export type RemoveCommentMutationResult = ApolloReactCommon.MutationResult<RemoveCommentMutation>;
export type RemoveCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveCommentMutation, RemoveCommentMutationVariables>;
export const RemoveExplorationTagDocument = gql`
    mutation removeExplorationTag($data: RemoveExplorationTagInput) {
  removeExplorationTag(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type RemoveExplorationTagMutationFn = ApolloReactCommon.MutationFunction<RemoveExplorationTagMutation, RemoveExplorationTagMutationVariables>;

/**
 * __useRemoveExplorationTagMutation__
 *
 * To run a mutation, you first call `useRemoveExplorationTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveExplorationTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeExplorationTagMutation, { data, loading, error }] = useRemoveExplorationTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveExplorationTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveExplorationTagMutation, RemoveExplorationTagMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveExplorationTagMutation, RemoveExplorationTagMutationVariables>(RemoveExplorationTagDocument, baseOptions);
      }
export type RemoveExplorationTagMutationHookResult = ReturnType<typeof useRemoveExplorationTagMutation>;
export type RemoveExplorationTagMutationResult = ApolloReactCommon.MutationResult<RemoveExplorationTagMutation>;
export type RemoveExplorationTagMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveExplorationTagMutation, RemoveExplorationTagMutationVariables>;
export const RemoveReferenceTagDocument = gql`
    mutation removeReferenceTag($data: RemoveReferenceTagInput) {
  removeReferenceTag(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type RemoveReferenceTagMutationFn = ApolloReactCommon.MutationFunction<RemoveReferenceTagMutation, RemoveReferenceTagMutationVariables>;

/**
 * __useRemoveReferenceTagMutation__
 *
 * To run a mutation, you first call `useRemoveReferenceTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReferenceTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReferenceTagMutation, { data, loading, error }] = useRemoveReferenceTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveReferenceTagMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveReferenceTagMutation, RemoveReferenceTagMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveReferenceTagMutation, RemoveReferenceTagMutationVariables>(RemoveReferenceTagDocument, baseOptions);
      }
export type RemoveReferenceTagMutationHookResult = ReturnType<typeof useRemoveReferenceTagMutation>;
export type RemoveReferenceTagMutationResult = ApolloReactCommon.MutationResult<RemoveReferenceTagMutation>;
export type RemoveReferenceTagMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveReferenceTagMutation, RemoveReferenceTagMutationVariables>;
export const RemoveNoteDocument = gql`
    mutation removeNote($data: RemoveNoteInput) {
  removeNote(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type RemoveNoteMutationFn = ApolloReactCommon.MutationFunction<RemoveNoteMutation, RemoveNoteMutationVariables>;

/**
 * __useRemoveNoteMutation__
 *
 * To run a mutation, you first call `useRemoveNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNoteMutation, { data, loading, error }] = useRemoveNoteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveNoteMutation, RemoveNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveNoteMutation, RemoveNoteMutationVariables>(RemoveNoteDocument, baseOptions);
      }
export type RemoveNoteMutationHookResult = ReturnType<typeof useRemoveNoteMutation>;
export type RemoveNoteMutationResult = ApolloReactCommon.MutationResult<RemoveNoteMutation>;
export type RemoveNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveNoteMutation, RemoveNoteMutationVariables>;
export const RemoveReferenceDocument = gql`
    mutation removeReference($data: RemoveReferenceInput) {
  removeReference(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type RemoveReferenceMutationFn = ApolloReactCommon.MutationFunction<RemoveReferenceMutation, RemoveReferenceMutationVariables>;

/**
 * __useRemoveReferenceMutation__
 *
 * To run a mutation, you first call `useRemoveReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeReferenceMutation, { data, loading, error }] = useRemoveReferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useRemoveReferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveReferenceMutation, RemoveReferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveReferenceMutation, RemoveReferenceMutationVariables>(RemoveReferenceDocument, baseOptions);
      }
export type RemoveReferenceMutationHookResult = ReturnType<typeof useRemoveReferenceMutation>;
export type RemoveReferenceMutationResult = ApolloReactCommon.MutationResult<RemoveReferenceMutation>;
export type RemoveReferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveReferenceMutation, RemoveReferenceMutationVariables>;
export const UpdateCommentDocument = gql`
    mutation updateComment($data: UpdateCommentInput) {
  updateComment(data: $data) {
    comment {
      ...commentAttributes
    }
  }
}
    ${CommentAttributesFragmentDoc}`;
export type UpdateCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, baseOptions);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = ApolloReactCommon.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const UpdateExplorationDocument = gql`
    mutation updateExploration($data: UpdateExplorationInput) {
  updateExploration(data: $data) {
    exploration {
      ...explorationAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${ExplorationAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type UpdateExplorationMutationFn = ApolloReactCommon.MutationFunction<UpdateExplorationMutation, UpdateExplorationMutationVariables>;

/**
 * __useUpdateExplorationMutation__
 *
 * To run a mutation, you first call `useUpdateExplorationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplorationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplorationMutation, { data, loading, error }] = useUpdateExplorationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateExplorationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExplorationMutation, UpdateExplorationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExplorationMutation, UpdateExplorationMutationVariables>(UpdateExplorationDocument, baseOptions);
      }
export type UpdateExplorationMutationHookResult = ReturnType<typeof useUpdateExplorationMutation>;
export type UpdateExplorationMutationResult = ApolloReactCommon.MutationResult<UpdateExplorationMutation>;
export type UpdateExplorationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExplorationMutation, UpdateExplorationMutationVariables>;
export const UpdateExplorationTagsDocument = gql`
    mutation updateExplorationTags($data: UpdateExplorationTagsInput) {
  updateExplorationTags(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type UpdateExplorationTagsMutationFn = ApolloReactCommon.MutationFunction<UpdateExplorationTagsMutation, UpdateExplorationTagsMutationVariables>;

/**
 * __useUpdateExplorationTagsMutation__
 *
 * To run a mutation, you first call `useUpdateExplorationTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplorationTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplorationTagsMutation, { data, loading, error }] = useUpdateExplorationTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateExplorationTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExplorationTagsMutation, UpdateExplorationTagsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExplorationTagsMutation, UpdateExplorationTagsMutationVariables>(UpdateExplorationTagsDocument, baseOptions);
      }
export type UpdateExplorationTagsMutationHookResult = ReturnType<typeof useUpdateExplorationTagsMutation>;
export type UpdateExplorationTagsMutationResult = ApolloReactCommon.MutationResult<UpdateExplorationTagsMutation>;
export type UpdateExplorationTagsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExplorationTagsMutation, UpdateExplorationTagsMutationVariables>;
export const UpdateReferenceDocument = gql`
    mutation updateReference($data: UpdateReferenceInput) {
  updateReference(data: $data) {
    reference {
      ...referenceAttributes
    }
    errors {
      ...errorAttributes
    }
  }
}
    ${ReferenceAttributesFragmentDoc}
${ErrorAttributesFragmentDoc}`;
export type UpdateReferenceMutationFn = ApolloReactCommon.MutationFunction<UpdateReferenceMutation, UpdateReferenceMutationVariables>;

/**
 * __useUpdateReferenceMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceMutation, { data, loading, error }] = useUpdateReferenceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReferenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferenceMutation, UpdateReferenceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferenceMutation, UpdateReferenceMutationVariables>(UpdateReferenceDocument, baseOptions);
      }
export type UpdateReferenceMutationHookResult = ReturnType<typeof useUpdateReferenceMutation>;
export type UpdateReferenceMutationResult = ApolloReactCommon.MutationResult<UpdateReferenceMutation>;
export type UpdateReferenceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferenceMutation, UpdateReferenceMutationVariables>;
export const UpdateReferenceTagsDocument = gql`
    mutation updateReferenceTags($data: UpdateReferenceTagsInput) {
  updateReferenceTags(data: $data) {
    errors {
      ...errorAttributes
    }
  }
}
    ${ErrorAttributesFragmentDoc}`;
export type UpdateReferenceTagsMutationFn = ApolloReactCommon.MutationFunction<UpdateReferenceTagsMutation, UpdateReferenceTagsMutationVariables>;

/**
 * __useUpdateReferenceTagsMutation__
 *
 * To run a mutation, you first call `useUpdateReferenceTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReferenceTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReferenceTagsMutation, { data, loading, error }] = useUpdateReferenceTagsMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateReferenceTagsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateReferenceTagsMutation, UpdateReferenceTagsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateReferenceTagsMutation, UpdateReferenceTagsMutationVariables>(UpdateReferenceTagsDocument, baseOptions);
      }
export type UpdateReferenceTagsMutationHookResult = ReturnType<typeof useUpdateReferenceTagsMutation>;
export type UpdateReferenceTagsMutationResult = ApolloReactCommon.MutationResult<UpdateReferenceTagsMutation>;
export type UpdateReferenceTagsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateReferenceTagsMutation, UpdateReferenceTagsMutationVariables>;