import Grid from '@material-ui/core/Grid';
import React from 'react';
import TagPreview from '../TagPreview/TagPreview';
import styles from './TagsList.module.scss';

const TagsList = ({ tags }: { tags: any }) => (
    <Grid item xs={10} className={styles.TagsList}>
        <Grid container direction="row" spacing={8} justify="space-between" alignItems="center">
            {tags.results.map(
                (tag: any) => (
                    <Grid key={tag.id} item xs={2}>
                        <TagPreview tag={tag} />
                    </Grid>
                )
            )}
        </Grid>
    </Grid>
);

export default TagsList;
