import { Config } from "./Config";

/** Capitalizes first letter of the provided string, returns the rest of string unchanged. */
export const capitalize = (s: string): string => {
    return s.charAt(0).toUpperCase() + s.substring(1);
}

/** Taken from https://hisk.io/javascript-snake-to-camel */
export const snakeToCamel = (str: string): string => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
        .replace('-', '')
        .replace('_', '')
);

/** Given the current reference state, returns the next reference state */
export const getNextReferenceState = (current: string): string => {
    const nextStateMap: any = {
        NOT_READ: 'READING',
        READING: 'READ',
        READ: 'READ_LATER',
        READ_LATER: 'NOT_READ'
    };
    return nextStateMap[current];
};

/** Taken from https://stackoverflow.com/a/8498629 */
export const extractDomain = (url: string): string | null => {
    if (url) {
        /* eslint-disable no-useless-escape */
        const matches = url.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
        const domain = matches && matches[1];  // domain will be null if no match is found
        return domain;
    }
    return null
}

/** Given the current exploration state, returns the next exploration state */
export const getNextExplorationState = (current: string): string => {
    const nextStateMap: any = {
        CREATED: 'PROGRESS',
        PROGRESS: 'RESOLVED',
        RESOLVED: 'CREATED',
    };
    return nextStateMap[current];
};

/** Maps collection to view */
export const getCollectionView = (collection: { type: string }): string => {
    const views: any = {
        IMAGE: 'references',
        VIDEO: 'player',
        GIST: 'gists',
        LINK: 'links',
        DOCUMENT: 'documents'
    };
    return views[collection.type];
}

/** Return path to collection and reference according to the reference type */
export const getCollectionLink = (reference: any): string => {
    const view = getCollectionView(reference);
    return `/${view}/${reference.id}`;
}

/** Returns the absolute path to a file in the api */
export const staticFile = (relative: string): string => {
    return Config.GRAPHQL_API + '/' + relative
}

/** taken from register-app */
export const errorParser = (response: any) => {

    let errors: any = {}

    const key = Object.keys(response.data)[0];
    const e = response.data[key].errors;

    if (e) {
        e.map((error: any) => errors[error.field] = error.message[0]);
    }

    return errors;
}
