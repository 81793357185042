import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { useCreateReferenceMutation, useReferenceLanguagesQuery, ReferenceTypes } from '../../generated/graphql';
import { useChangeHandler } from '../../services/Hooks';
import { GISTS } from '../../services/Queries';
import Loading from '../Loading/Loading';
import SingleSelector from '../SingleSelector/SingleSelector';
import styles from './CreateGist.module.scss';

const CodeField = ({ onChange, show }: { onChange: any, show: any }) => {
    if (!show) {
        return null;
    }
    return <TextField name="code" placeholder="code" multiline onChange={onChange} />;
};

const CodeHighlighter = ({ language, data, code }: { language: any, data: any, code: any }) => {
    if (!code || !data) {
        return null;
    }

    const lang = data.referenceLanguages.results.find((obj: any) => obj.id === language);

    return (
        <SyntaxHighlighter language={lang.name} style={docco}>
            {code}
        </SyntaxHighlighter>
    );
};

const CreateGist = ({ onComplete }: { onComplete: any }) => {

    const [gist, setGist] = useState({ language: '', code: null, type: ReferenceTypes.Gist });
    const [createGist] = useCreateReferenceMutation()
    const { loading, data } = useReferenceLanguagesQuery()
    const changeHandler = useChangeHandler(gist, setGist);

    if (loading) {
        return <Loading />;
    }

    const handleSubmit = () => {
        createGist({ variables: { data: { ...gist } }, refetchQueries: [{ query: GISTS, variables: { page: 1, search: "", tags: "" } }] }).then(() => onComplete());
    };

    return (
        <Grid container direction="column" className={styles.CreateGist}>
            <TextField name="name" placeholder="name" onChange={changeHandler} autoFocus />
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <SingleSelector name="language" value={gist.language} data={data} onChange={changeHandler} />
            <CodeField show={gist.language} onChange={changeHandler} />
            <CodeHighlighter data={data} code={gist.code} language={gist.language} />
            <Button onClick={handleSubmit}>Create Gist</Button>
        </Grid>
    );
};

export default CreateGist;
