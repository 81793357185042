import 'moment/locale/pt';
import React from 'react';
import Moment from 'react-moment';

const FormatDate = ({ date, format }: { date: any, format?: any }) => {
    return (
        <Moment format={format ? format : "DD-MM-YYYY HH:mm"}>
            {date}
        </Moment>);
};

export default FormatDate;
