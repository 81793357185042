import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { useCommentsLazyQuery } from '../../generated/graphql';
import { useGuestRedirection } from '../../services/Hooks';
import AutoTextfield from '../AutoTextField/AutoTextField';
import Loading from '../Loading/Loading';
import styles from "./SearchView.module.scss";

const SearchResult = (props: any) => {
    return (
        <div className={styles.SearchResult}>
            <p>{props.comment?.description}</p>
            <p>{props.comment?.exploration ? <small><b>Exploração:</b> {props.comment?.exploration?.name}</small> : null}</p>
            <p>{props.comment?.reference ? <small><b>Referência:</b> {props.comment?.reference?.name}</small> : null}</p>
        </div>
    )
}

const SearchView = () => {

    useGuestRedirection();

    const [filters, setFilters] = useState({ search: '' });

    const [getComments, { loading, data }] = useCommentsLazyQuery()

    if (loading) {
        return <Loading />;
    }

    const onSearch = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ search: searchTerm });
        getComments({ variables: { search: searchTerm } });
    };

    return (
        <Grid container direction="column" alignItems="center">
            <AutoTextfield
                placeholder="search"
                value={filters.search}
                name="fullSearch"
                onSave={onSearch}
                interval={500}
                autoFocus
                autoComplete="off"
            />
            <div>
                {data?.comments?.count} resultados encontrados
            </div>
            <div>
                {data?.comments?.results && data?.comments?.results.map(
                    comment => <SearchResult key={comment?.id} comment={comment} />
                )}
            </div>
        </Grid>
    );
};

export default SearchView;
