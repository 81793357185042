import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import { useReferencesQuery } from '../../generated/graphql';
import { useFocus } from '../../services/Hooks';

const useStyles = makeStyles({
    input: {
        color: '#4a4a4a',
    },
    inputRoot: {
        borderBottom: '1px solid white',
        marginBottom: 20
    }
});


const ExplorationReference = ({ addReference }: { addReference: any }) => {
    const [selected, setSelected] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    // @ts-ignore
    const { data, loading } = useReferencesQuery({ variables: { page: 1, search: searchTerm } })
    const classes = useStyles();
    const [inputRef] = useFocus();


    // @ts-ignore
    const options = !data ? [] : data.references.results.map((option: any) => {
        const firstLetter = option.name ? option.name[0].toUpperCase() : '';
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const handleChange = (event: any, value: any) => {
        setSelected(value.id || null);
    };

    const inputLabelProps = { style: { color: 'white' } };

    return (
        <Grid container direction="column">
            <Autocomplete
                classes={{
                    input: classes.input,
                    clearIndicator: classes.input,
                    popupIndicator: classes.input,
                    inputRoot: classes.inputRoot
                }}
                options={options.sort((a: any, b: any) => -b.firstLetter.localeCompare(a.firstLetter))}
                onChange={handleChange}
                noOptionsText="No references found for query"
                loading={loading}
                inputValue={searchTerm || ''}
                clearOnEscape
                groupBy={(option: any) => option.type}
                getOptionLabel={option => option ? (`#${option.id} - ${option.name}`) : ''}
                style={{ width: '100%', color: '#fff' }}
                renderInput={
                    params => <TextField {...params}
                        label="search"
                        InputLabelProps={inputLabelProps}
                        inputRef={inputRef}
                        onChange={(event: any) => setSearchTerm(event.target.value)}
                    />}
            />

            <Button onClick={() => addReference(selected)} style={{ marginTop: '25px' }}>
                Add Reference
          </Button>
        </Grid>
    );
};

export default ExplorationReference;
