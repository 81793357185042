import React, { useState } from 'react';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import CreateExploration from '../CreateExploration/CreateExploration';
import CustomModal from '../CustomModal/CustomModal';
import ExplorationsList from '../ExplorationsList/ExplorationsList';


const ExplorationsView = () => {

    useGuestRedirection();

    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    return (
        <>
            <ExplorationsList />
            <CustomModal isActive={isModalOpen} title="Create Exploration" handleClose={() => setModalOpen(false)}>
                <CreateExploration onComplete={() => setModalOpen(false)} />
            </CustomModal>
        </>
    );
};

export default ExplorationsView;
