import gql from 'graphql-tag';

// QUERIES

export const LOGGED_IN_USER = gql`
query {
    token @client
    username @client
}
`;

// MUTATIONS

export const LOGIN_USER = gql`
mutation loginUser($token: String!, $user: User!) {
    loginUser(token: $token, user: $user) @client
}
`;

export const LOGOUT_USER = gql`
mutation logoutUser {
    logoutUser @client
}
`;

export const IS_MENU_OPEN = gql`
query {
    isMenuOpen @client
}
`;

export const SET_MENU = gql`
mutation setMenu($state: Boolean!) {
    setMenu(state: $state) @client
}
`;
