import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useCreateExplorationMutation } from '../../generated/graphql';
import { useChangeHandler } from '../../services/Hooks';
import { EXPLORATIONS } from '../../services/Queries';

const CreateExploration = ({ onComplete }: { onComplete: any }) => {

    const [link, setExploration] = useState();
    const [createExploration] = useCreateExplorationMutation()
    const changeHandler = useChangeHandler(link, setExploration);

    const handleSubmit = () => {
        createExploration({ variables: { data: { ...link } }, refetchQueries: [{ query: EXPLORATIONS, variables: { page: 1 } }] }).then(response => onComplete());
    };

    return (
        <Grid container direction="column">
            <TextField required name="name" placeholder="name" onChange={changeHandler} />
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <TextField name="summary" placeholder="summary" onChange={changeHandler} />
            <Button onClick={handleSubmit}>Create Exploration</Button>
        </Grid>
    );
};

export default CreateExploration;
