import gql from 'graphql-tag';

// fragments

const COMMENT_ATTRIBUTES = gql`
fragment commentAttributes on Comment {
    id
    description
    x
    y
    time
    user {
        name
    }
    createdAt
    color
    collapsed
}
`;

const DOCUMENT_ATTRIBUTES = gql`
fragment documentAttributes on Reference {
    id
    name
    description
    type
    remoteFile
    state
    comments {
        ...commentAttributes
    }
    referenceTags {
        tag {
            id
        }
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;

const ERROR_ATTRIBUTES = gql`
fragment errorAttributes on Error {
    field
    message
}
`

const EXPLORATION_ATTRIBUTES = gql`
fragment explorationAttributes on Exploration {
    id
    name
    description
    summary
    state
    comments {
        ...commentAttributes
    }
    explorationTags {
        tag {
            id
        }
    }
    explorationReferences {
        reference {
            id
            name
            type
        }
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;

const GIST_ATTRIBUTES = gql`
fragment gistAttributes on Reference {
    id
    name
    description
    type
    code
    referenceLanguage {
        id
        name
    }
    createdAt
    updatedAt
}
`;

const GRAPHIC_ATTRIBUTES = gql`
fragment graphicAttributes on Reference {
    id
    name
    description
    file
    thumbnail
    prayers
    type
    comments {
        ...commentAttributes
    }
    referenceTags {
        tag {
            id
        }
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;

const LINK_ATTRIBUTES = gql`
fragment linkAttributes on Reference {
    id
    name
    description
    type
    url
    state
    comments {
        ...commentAttributes
    }
    referenceTags {
        tag {
            id
        }
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;


const REFERENCE_ATTRIBUTES = gql`
fragment referenceAttributes on Reference {
    id
    name
    description
    file
    thumbnail
    prayers
    type
    comments {
        ...commentAttributes
    }
    referenceTags {
        tag {
            id
        }
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;

const TAG_ATTRIBUTES = gql`
fragment tagAttributes on Tag {
    id
    description
    referencesCount
    createdAt
    updatedAt
}
`;

const VIDEO_ATTRIBUTES = gql`
fragment videoAttributes on Reference {
    id
    name
    description
    file
    thumbnail
    prayers
    type
    comments {
        ...commentAttributes
    }
    createdAt
    updatedAt
}
${COMMENT_ATTRIBUTES}
`;


// queries

export const EXPLORATIONS = gql`
query explorations($page: Int!, $search: String, $tag: ID){
    explorations(page: $page, search: $search, tag: $tag) {
        count
        results {
            ...explorationAttributes
        }
    }
}
${EXPLORATION_ATTRIBUTES}
`;


export const EXPLORATION = gql`
query exploration($id: ID!) {
    exploration(id: $id) {
        ...explorationAttributes
    }
}
${EXPLORATION_ATTRIBUTES}
`;

export const REFERENCES = gql`
query references($page: Int!, $search: String){
    references(page: $page, search: $search) {
        count
        results {
            ...referenceAttributes
        }
    }
}
${REFERENCE_ATTRIBUTES}
`;

export const REFERENCE = gql`
query reference($id: ID!){
    reference(id: $id) {
        id
        name
        description
        file
    }
}
`;

export const REFERENCE_LANGUAGES = gql`
query referenceLanguages {
    referenceLanguages {
        count
        results {
            id
            name
            createdAt
            updatedAt
        }
    }
}
`;

export const GISTS = gql`
query gists($page: Int!, $search: String, $language: ID){
    references(page: $page, search: $search, type: [GIST], language: $language) {
        count
        results {
            ...gistAttributes
        }
    }
}
${GIST_ATTRIBUTES}
`;

export const GIST = gql`
query gist($id: ID!) {
    reference(id: $id) {
        ...gistAttributes
    }
}
${GIST_ATTRIBUTES}
`;

export const GRAPHICS = gql`
query graphics($page: Int!, $search: String, $tag: ID){
    references(page: $page, search: $search, tag: $tag, type: [IMAGE, VIDEO],) {
        count
        results {
            ...referenceAttributes
        }
    }
}
${REFERENCE_ATTRIBUTES}
`;

export const GRAPHIC = gql`
query graphic($id: ID!){
    reference(id: $id) {
        ...graphicAttributes
    }
}
${GRAPHIC_ATTRIBUTES}
`;

export const LINKS = gql`
query links($page: Int!, $search: String, $tag: ID){
    references(page: $page, search: $search, type: [LINK], tag: $tag) {
        count
        results {
            ...linkAttributes
        }
    }
}
${LINK_ATTRIBUTES}
`;

export const LINK = gql`
query link($id: ID!) {
    reference(id: $id) {
        ...linkAttributes
    }
}
${LINK_ATTRIBUTES}
`;


export const TAGS = gql`
query tags($page: Int, $pageSize: Int, $search: String) {
    tags(page: $page, pageSize: $pageSize, search: $search) {
        count
        results {
            id
            referencesCount
            description
        }
    }
}
`;

export const VIDEO = gql`
query video($id: ID!){
    reference(id: $id) {
        ...videoAttributes
    }
}
${VIDEO_ATTRIBUTES}
`;

export const COMMENTS = gql`
query comments($search: String){
    comments(search: $search, pageSize: 9999, page: 1) {
        count
        results {
            id
            description
            exploration {
                id
                name
            }
            reference {
                id
                name
            }
        }
    }
}
`;


export const DOCUMENTS = gql`
query documents($page: Int!, $search: String, $tag: ID){
    references(page: $page, type: [DOCUMENT], search: $search, tag: $tag) {
        count
        results {
            ...documentAttributes
        }
    }
}
${DOCUMENT_ATTRIBUTES}
`;


export const DOCUMENT = gql`
query document($id: ID!) {
    reference(id: $id) {
        ...documentAttributes
    }
}
${DOCUMENT_ATTRIBUTES}
`;

export const FLEETING_NOTES = gql`
query fleetingNotes($page: Int!, $search: String) {
    comments(page: $page, pageSize: 9999, reference: null, exploration: null, search: $search) {
        count
        results {
            id
            description
        }
    }
}
`;

// mutations

export const CREATE_COMMENT = gql`
mutation createComment($data: CreateCommentInput) {
    createComment(data: $data) {
        comment {
            ...commentAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${COMMENT_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const CREATE_EXPLORATION = gql`
mutation createExploration($data: CreateExplorationInput) {
    createExploration(data: $data) {
        exploration {
            ...explorationAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${EXPLORATION_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const CREATE_EXPLORATION_REFERENCE = gql`
mutation createExplorationReference($data: CreateExplorationReferenceInput) {
    createExplorationReference(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const CREATE_EXPLORATION_TAG = gql`
mutation createExplorationTag($data: CreateExplorationTagInput) {
    createExplorationTag(data: $data) {
        exploration {
            ...explorationAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${EXPLORATION_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const CREATE_REFERENCE = gql`
mutation createReference($data: CreateReferenceInput) {
    createReference(data: $data) {
        reference {
            ...referenceAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${REFERENCE_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const CREATE_REFERENCE_TAG = gql`
mutation createReferenceTag($data: CreateReferenceTagInput) {
    createReferenceTag(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const CREATE_TAG = gql`
mutation createTag($data: CreateTagInput) {
    createTag(data: $data) {
        tag {
            ...tagAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${TAG_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const LOGIN = gql`
mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
        user {
            id
            name
        }
        token
        errors {
            field
            message
        }
    }
}
`;

export const REMOVE_COMMENT = gql`
mutation removeComment($data: RemoveCommentInput) {
    removeComment(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const REMOVE_EXPLORATION_TAG = gql`
mutation removeExplorationTag($data: RemoveExplorationTagInput) {
    removeExplorationTag(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;


export const REMOVE_REFERENCE_TAG = gql`
mutation removeReferenceTag($data: RemoveReferenceTagInput) {
    removeReferenceTag(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;


export const REMOVE_NOTE = gql`
mutation removeNote($data: RemoveNoteInput) {
    removeNote(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const REMOVE_REFERENCE = gql`
mutation removeReference($data: RemoveReferenceInput) {
    removeReference(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const UPDATE_COMMENT = gql`
mutation updateComment($data: UpdateCommentInput) {
    updateComment(data: $data) {
        comment {
            ...commentAttributes
        }
    }
}
${COMMENT_ATTRIBUTES}
`;

export const UPDATE_EXPLORATION = gql`
mutation updateExploration($data: UpdateExplorationInput) {
    updateExploration(data: $data) {
        exploration {
            ...explorationAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${EXPLORATION_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;


export const UPDATE_EXPLORATION_TAGS = gql`
mutation updateExplorationTags($data: UpdateExplorationTagsInput) {
    updateExplorationTags(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;

export const UPDATE_REFERENCE = gql`
mutation updateReference($data: UpdateReferenceInput) {
    updateReference(data: $data) {
        reference {
            ...referenceAttributes
        }
        errors {
            ...errorAttributes
        }
    }
}
${REFERENCE_ATTRIBUTES}
${ERROR_ATTRIBUTES}
`;

export const UPDATE_REFERENCE_TAGS = gql`
mutation updateReferenceTags($data: UpdateReferenceTagsInput) {
    updateReferenceTags(data: $data) {
        errors {
            ...errorAttributes
        }
    }
}
${ERROR_ATTRIBUTES}
`;
