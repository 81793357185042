import React, { useState } from 'react';
import { useGlobalFab, useGuestRedirection } from '../../services/Hooks';
import CreateLink from '../CreateLink/CreateLink';
import CustomModal from '../CustomModal/CustomModal';
import LinksList from '../LinksList/LinksList';


const LinksView = () => {

    useGuestRedirection();

    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    return (
        <>
            <LinksList />
            <CustomModal isActive={isModalOpen} title="Create Link" handleClose={() => setModalOpen(false)}>
                <CreateLink onComplete={() => setModalOpen(false)} />
            </CustomModal>
        </>
    );
};

export default LinksView;
