import { Card, CardContent, CardMedia } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import image from '../../assets/images/link.png';
import { extractDomain, snakeToCamel } from '../../services/Utils';
import ReadIcon from '../ReadIcon/ReadIcon';
import styles from './LinkPreview.module.scss';

// @ts-ignore
const LinkPreview = ({ link, selected, ...props }) => {
    const to = `/links/${link.id}`;

    const handleSelect = (event: any) => {
        event.preventDefault();
        const action = selected ? 'remove' : 'add';
        props.handleSelect(link.id, action);
    };

    const classes = () => {
        const classes = [styles.ReadIcon];
        const state_name = snakeToCamel(link.state.toLowerCase());
        classes.push(styles[state_name]);
        return classes;
    };

    return (
        <div className={classNames(styles.LinkPreview, { [styles.Selected]: selected })}>
            <Link to={to}>
                <Card style={{ position: 'relative' }}>
                    <ReadIcon className={classNames(classes())} />
                    <CardMedia
                        image={image}
                        // @ts-ignore
                        title={document.name}
                        className={styles.LinkImage}
                    />
                    <CardContent className={styles.CardContent}>
                        <div>
                            <p className={styles.Name}>#{link.id} {link.name}</p>
                            <p className={styles.Domain}>{extractDomain(link.url)}</p>
                        </div>
                    </CardContent>
                </Card>
                <Grid container direction="column" className={styles.Overlay} justify="space-between">
                    <Icon className={classNames(styles.CheckIcon, { [styles.CheckIconActive]: selected })} onClick={handleSelect}>
                        <CheckCircleIcon />
                    </Icon>
                    <div className={styles.Tags}>
                        {0 && link.tags.map((tag: any) => tag.id).join('; ')}
                    </div>
                </Grid>
            </Link>
        </div>
    );
};


export default LinkPreview;
