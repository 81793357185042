import { Card, CardContent, CardMedia } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import MovieIcon from '@material-ui/icons/Movie';
import PhotoIcon from '@material-ui/icons/Photo';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import PrayIcon from '../PrayIcon/PrayIcon';
import styles from './ReferencePreview.module.scss';
import { staticFile } from '../../services/Utils';

// @ts-ignore
const ReferencePreview = ({ reference, selected, ...props }) => {

    console.log('reference preview ', reference)

    const image = staticFile(reference.thumbnail)
    const icon = reference.type === 'IMAGE' ? <PhotoIcon /> : <MovieIcon />;
    const to = reference.type === 'IMAGE' ? `/references/${reference.id}` : `/player/${reference.id}`;

    const handleUpdate = (event: any) => {
        event.preventDefault();
        props.handleUpdate({ id: reference.id, prayers: reference.prayers + 1 });
    };

    const handleSelect = (event: any) => {
        event.preventDefault();
        const action = selected ? 'remove' : 'add';
        props.handleSelect(reference.id, action);
    };

    return (
        <div className={classNames(styles.ReferencePreview, { [styles.Selected]: selected })}>
            <Link to={to}>
                <Card style={{ position: 'relative' }}>
                    <CardMedia
                        image={image}
                        title={reference.name}
                        className={styles.ReferenceImage}
                    />
                    <CardContent className={styles.CardContent}>
                        <Grid container justify="space-between" alignItems="center">
                            #{reference.id} {reference.name}
                            <div style={{ position: 'relative' }}>
                                <small style={{ position: 'absolute', top: '-5px', left: '30px', fontSize: '10px' }}>{reference.prayers}</small>
                                <Icon className={classNames({ [styles.Icon]: true, [styles.IconActive]: reference.prayers })} onClick={handleUpdate}>
                                    <PrayIcon />
                                </Icon>
                                <Icon className={styles.Icon}>
                                    {icon}
                                </Icon>
                            </div>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container direction="column" className={styles.Overlay} justify="space-between">
                    <Icon className={classNames(styles.CheckIcon, { [styles.CheckIconActive]: selected })} onClick={handleSelect}>
                        <CheckCircleIcon />
                    </Icon>
                    <div className={styles.Tags}>
                        {reference?.referenceTags && reference.referenceTags.map((referenceTag: any) => referenceTag.tag.id).join('; ')}
                    </div>
                </Grid>
            </Link>
        </div>
    );
};

export default ReferencePreview;
