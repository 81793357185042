import { Card, CardContent, CardHeader } from '@material-ui/core';
import React from 'react';
import styles from './TagPreview.module.scss';

const TagPreview = ({ tag }: { tag: any }) => {
    return (
        <Card className={styles.TagPreview}>
            <CardHeader title={tag.id} />
            <CardContent>
                <p>{tag.description ? tag.description : 'Sem Descrição.'}</p>
                <p>
                    <small>#{tag.referencesCount}</small>
                </p>
            </CardContent>
        </Card>
    );
};

export default TagPreview;
