import React from 'react';
import AutoTextfield from '../AutoTextField/AutoTextField';

const ScopedSearch = ({ value, onSearch }: { value: any, onSearch: any }) => (
    <AutoTextfield
        placeholder="search"
        value={value}
        name="fullSearch"
        onSave={onSearch}
        interval={500}
        autoFocus
        autoComplete="off"
    />
);

export default ScopedSearch;
