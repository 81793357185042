import React, { useState } from 'react';
import { useGlobalFab } from '../../services/Hooks';
import CreateGist from '../CreateGist/CreateGist';
import CustomModal from '../CustomModal/CustomModal';
import GistsHero from '../GistsHero/GistsHero';
import GistsList from '../GistsList/GistsList';

const GistsView = () => {

    const [isModalOpen, setModalOpen] = useState(false);

    useGlobalFab(() => setModalOpen(true));

    return (
        <>
            <GistsHero />
            <GistsList />
            <CustomModal isActive={isModalOpen} title="Create Gist" handleClose={() => setModalOpen(false)}>
                <CreateGist onComplete={() => setModalOpen(false)} />
            </CustomModal>
        </>
    );
};

export default GistsView;
