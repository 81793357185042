import Grid from '@material-ui/core/Grid';
import React from 'react';
import img from '../../assets/images/image.svg';
import styles from './GistsHero.module.scss';

const GistsHero = (props: any) => {
    return (
        <Grid container direction="row" justify="center" alignItems="center" className={styles.GistsHero}>
            <Grid item xs={4}>
                <h1>
                    Good code is hard to find. Keep it here, <b>warm & cosy.</b>
                </h1>
                <p>Refman is the all-in-one reference manager. We'll handle your images, copy, code snippets and random thoughts.</p>
            </Grid>
            <Grid item xs={2} style={{ paddingTop: '50px' }}>
                <img src={img} style={{ maxHeight: '400px' }} alt="Refman Hero" />
            </Grid>
        </Grid>
    );
};

export default GistsHero;
