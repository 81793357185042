import { useMutation } from '@apollo/react-hooks';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import LaunchIcon from '@material-ui/icons/Launch';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { useCreateCommentMutation, useCreateReferenceTagMutation, useLinkQuery, useRemoveCommentMutation, useUpdateCommentMutation, useRemoveReferenceTagMutation } from '../../generated/graphql';
import { Config } from '../../services/Config';
import { UPDATE_REFERENCE } from '../../services/Queries';
import { getNextReferenceState, snakeToCamel } from '../../services/Utils';
import AutoTextField from '../AutoTextField/AutoTextField';
import Loading from '../Loading/Loading';
import ReadIcon from '../ReadIcon/ReadIcon';
import ReferenceComment from '../ReferenceComment/ReferenceComment';
import TagSelector from '../TagSelector/TagSelector';
import styles from './LinkDetails.module.scss';
import useDocumentTitle from '@rehooks/document-title';

const LinkTitle = ({ link, onSave }: { link: any, onSave: any }) => {
    const [editable, setEditable] = useState(false);
    return (
        <div className={styles.LinkTitle}>
            {!editable && <h3 onClick={() => setEditable(!editable)}>{link.name}</h3>}
            {editable && <AutoTextField
                name="name"
                label="name"
                type="text"
                fullWidth
                value={link.name !== null ? link.name : ''}
                onSave={onSave}
            />
            }
            <p>{link.url}</p>
        </div>
    );
};


const LinkDetails = ({ id }: { id: any }) => {
    const { data, loading, refetch } = useLinkQuery({ variables: { id } })
    const [updateReference] = useMutation(UPDATE_REFERENCE);
    const [updateComment] = useUpdateCommentMutation()
    const [createComment] = useCreateCommentMutation()
    const [deleteComment] = useRemoveCommentMutation()
    const [createReferenceTag] = useCreateReferenceTagMutation()
    const [removeReferenceTag] = useRemoveReferenceTagMutation()

    useDocumentTitle(data?.reference?.name ? data.reference.name : 'Refman');

    const clipboard = useClipboard({ copiedTimeout: 600 });

    if (loading) {
        return <Loading />;
    }

    const link = data?.reference;

    const handleToggle = () => {
        // @ts-ignore
        const state = getNextReferenceState(link.state);
        updateReference({ variables: { id, state } }).then(() => refetch());
    };

    const addNewComment = (x: any, y: any) => {
        // @ts-ignore
        const reference = link.id;
        // @ts-ignore
        createComment({ variables: { data: { reference, x, y, description: '' } } }).then(refetch);
    };

    const handleClick = (event: any) => {
        const x = event.nativeEvent.clientX;
        const y = event.nativeEvent.clientY;
        addNewComment(x, y);
    };

    const handleRemove = (comment: any) => {
        // @ts-ignore
        deleteComment({ variables: { data: { comment: comment.id } } }).then(refetch);
    };

    const handleUpdateComment = (data: any) => {
        const { id, ...restData } = data
        updateComment({ variables: { data: { comment: id, ...restData } } });
    };


    const classes = () => {
        const classes = [styles.ReadIcon];
        // @ts-ignore
        const state_name = snakeToCamel(link.state.toLowerCase());
        classes.push(styles[state_name]);
        return classes;
    };

    const handleSave = (data: any) => {
        // @ts-ignore
        updateReference({ variables: { data: { reference: link.id, ...data } } });
    };

    const handleQuote = () => {
        // @ts-ignore
        const quote = `[${link.name}](${Config.APP_URL + '/links/' + link.id} "Open in Refman")`;
        clipboard.copy(quote);
    };

    const handleOpen = () => {
        // @ts-ignore
        window.open(link.url);
    };

    const updateTags = (event: any, value: any, reason: any) => {

        if (reason === 'select-option') {
            const tag = value[value.length - 1];
            createReferenceTag({
                // @ts-ignore
                variables: { data: { reference: link.id, tag: tag.id } }
                // @ts-ignore
            }).then(refetch);
        }

        if (reason === 'remove-option') {
            // @ts-ignore
            const oldTags = link.referenceTags.map((referenceTag: any) => referenceTag.tag.id);
            const newTags = value.map((tag: any) => tag.id);
            let tag = oldTags.filter((x: any) => !newTags.includes(x));
            removeReferenceTag({
                // @ts-ignore
                variables: { data: { reference: link.id, tag: tag[0] } }
                // @ts-ignore
            }).then(refetch);
        }
    };

    const tags = () => {
        if (link?.referenceTags?.length) {
            return link?.referenceTags.map((item: any) => item.tag)
        }
    }

    return (
        <Grid container direction="column" className={styles.LinkPreviewDetails}>
            <Card>
                <CardHeader title={<LinkTitle link={link} onSave={handleSave} />}>
                </CardHeader>
                <CardContent>
                    <AutoTextField
                        name="description"
                        label="summary"
                        type="text"
                        multiline
                        fullWidth
                        // @ts-ignore
                        value={link.description !== null ? link.description : ''}
                        onSave={handleSave}
                    />
                    // @ts-ignore
                    <TagSelector value={tags()} onChange={updateTags} />
                </CardContent>
                <CardActions>
                    <Grid container justify="flex-end" alignItems="center">
                        // @ts-ignore
                        <Tooltip title={`open ${link.url} in a new window`}>
                            <IconButton onClick={handleOpen}>
                                <LaunchIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Create a reference to place in docs.lobo">
                            <IconButton onClick={handleQuote}>
                                <FormatQuoteIcon />
                            </IconButton>
                        </Tooltip>
                        <IconButton color="primary" className={styles.ToggleRead} onClick={handleToggle}>
                            // @ts-ignore
                            <ReadIcon className={classNames(classes())} tooltip={link.state} />
                        </IconButton>
                    </Grid>
                </CardActions>
            </Card>
            <div className={styles.CommentsBoard}>
                <div onClick={handleClick} className={styles.CommentsArea}>
                    // @ts-ignore
                    {link.comments.map((comment: any) => (
                        <ReferenceComment
                            key={comment.id}
                            comment={comment}
                            remove={handleRemove}
                            update={handleUpdateComment}
                        />))
                    }
                </div>
                // @ts-ignore
                <h4>{link.comments.length} comments</h4>
            </div>

        </Grid>
    );
};

export default LinkDetails;
