import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React from 'react';
import { useTagsQuery } from '../../generated/graphql';

const TagSelector = ({ value, onChange }: { value: any, onChange: any }) => {

    const { data } = useTagsQuery({ variables: { pageSize: 9999 } });

    const options = data?.tags?.results ? data.tags.results : [];

    return (
        <Autocomplete
            multiple
            disableClearable
            limitTags={3}
            options={options}
            value={value}
            onChange={onChange}
            getOptionSelected={(x, y) => x.id === y.id}
            getOptionLabel={(option) => option.id}
            renderInput={(params) => (
                <TextField {...params} label="Tags" placeholder="Tags" />
            )}
        />
    );
};

export default TagSelector;
