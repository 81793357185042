import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useChangeHandler } from '../../services/Hooks';
import styles from './CreateFleetingNote.module.scss';

const CreateFleetingNote = ({ onComplete, handleSubmit }: { onComplete: any, handleSubmit: any }) => {

    const [tag, setTag] = useState();
    const changeHandler = useChangeHandler(tag, setTag);

    return (
        <Grid container direction="column" className={styles.CreateFleetingNote}>
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <Button onClick={() => handleSubmit(tag)}>Create Fleeting Note</Button>
        </Grid>
    );
};

export default CreateFleetingNote;
