import { useMutation } from '@apollo/react-hooks';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useClipboard } from 'use-clipboard-copy';
import { useDocumentQuery, useRemoveCommentMutation } from '../../generated/graphql';
import { Config } from '../../services/Config';
import { CREATE_COMMENT, CREATE_REFERENCE_TAG, UPDATE_COMMENT, UPDATE_REFERENCE, REMOVE_REFERENCE_TAG } from '../../services/Queries';
import { getNextReferenceState, snakeToCamel } from '../../services/Utils';
import AutoTextField from '../AutoTextField/AutoTextField';
import ClipboardIcon from '../ClipboardIcon/ClipboardIcon';
import Loading from '../Loading/Loading';
import ReadIcon from '../ReadIcon/ReadIcon';
import ReferenceComment from '../ReferenceComment/ReferenceComment';
import TagSelector from '../TagSelector/TagSelector';
import styles from './DocumentDetails.module.scss';
import useDocumentTitle from '@rehooks/document-title';

const LinkTitle = ({ link, onSave }: { link: any, onSave: any }) => {
    const [editable, setEditable] = useState(false);
    return (
        <div className={styles.LinkTitle}>
            {!editable && <h3 onClick={() => setEditable(!editable)}>{link.name}</h3>}
            {editable && <AutoTextField
                name="name"
                label="name"
                type="text"
                fullWidth
                value={link.name !== null ? link.name : ''}
                onSave={onSave}
            />
            }
            <p>{link.remoteFile}</p>
        </div>
    );
};



const DocumentDetails = ({ id }: { id: any }) => {
    const { data, loading, refetch } = useDocumentQuery({ variables: { id } })
    const [updateReference] = useMutation(UPDATE_REFERENCE);
    const [updateComment] = useMutation(UPDATE_COMMENT);
    const [createComment] = useMutation(CREATE_COMMENT);
    const [deleteComment] = useRemoveCommentMutation()
    const [createReferenceTag] = useMutation(CREATE_REFERENCE_TAG);
    const [removeReferenceTag] = useMutation(REMOVE_REFERENCE_TAG);

    const clipboard = useClipboard({ copiedTimeout: 600 });

    useDocumentTitle(data?.reference?.name ? data.reference.name : 'Refman');

    if (loading) {
        return <Loading />;
    }

    const document = data?.reference;

    const handleToggle = () => {
        // @ts-ignore
        const state = getNextReferenceState(document.state);
        updateReference({ variables: { data: { reference: document?.id, state } } }).then(() => refetch());
    };

    const addNewComment = (x: any, y: any) => {
        // @ts-ignore
        createComment({ variables: { data: { reference: document.id, x, y, description: '' } } }).then(refetch);
    };

    const handleClick = (event: any) => {
        const x = event.nativeEvent.clientX;
        const y = event.nativeEvent.clientY;
        addNewComment(x, y);
    };

    const handleRemove = (comment: any) => {
        // @ts-ignore
        deleteComment({ variables: { data: { comment: comment.id } } }).then(refetch);
    };

    const handleUpdateComment = (data: any) => {
        const { id, ...restData } = data
        updateComment({ variables: { data: { comment: id, ...restData } } });
    };

    const classes = () => {
        const classes = [styles.ReadIcon];
        // @ts-ignore
        const state_name = snakeToCamel(document.state.toLowerCase());
        classes.push(styles[state_name]);
        return classes;
    };

    const handleSave = (data: any) => {
        updateReference({ variables: { data: { reference: document?.id, ...data } } });
    };

    const handleQuote = () => {
        // @ts-ignore
        const quote = `[${document.name}](${Config.APP_URL + '/documents/' + document.id} "Open in Refman")`;
        clipboard.copy(quote);
    };

    // @ts-ignore
    const updateTags = (event: any, value: any, reason: any) => {

        if (reason === 'select-option') {
            const tag = value[value.length - 1];
            createReferenceTag({
                // @ts-ignore
                variables: { data: { reference: document.id, tag: tag.id } }
                // @ts-ignore
            }).then(refetch);
        }

        if (reason === 'remove-option') {
            // @ts-ignore
            const oldTags = document.referenceTags.map((referenceTag: any) => referenceTag.tag.id);
            const newTags = value.map((tag: any) => tag.id);
            let tag = oldTags.filter((x: any) => !newTags.includes(x));
            removeReferenceTag({
                // @ts-ignore
                variables: { data: { reference: document.id, tag: tag[0] } }
                // @ts-ignore
            }).then(refetch);
        }
    };

    const tags = () => {
        if (document?.referenceTags?.length) {
            return document.referenceTags.map((item: any) => item.tag)
        }
    }

    return (
        <Grid container direction="column">
            <Card>
                <CardHeader title={<LinkTitle link={document} onSave={handleSave} />} />
                <CardContent>
                    <AutoTextField
                        name="description"
                        label="summary"
                        type="text"
                        multiline
                        fullWidth
                        // @ts-ignore
                        value={document.description}
                        onSave={handleSave}
                    />
                    <TagSelector value={tags()} onChange={updateTags} />
                </CardContent>
                <CardActions>
                    <Grid container justify="flex-end" alignItems="center">
                        <small className={styles.Copied}>
                            {clipboard.copied ? 'Copied' : ''}
                        </small>
                        <Button onClick={() => document && clipboard.copy(document.remoteFile)}>
                            <ClipboardIcon />
                        </Button>
                        <IconButton onClick={handleQuote}>
                            <FormatQuoteIcon />
                        </IconButton>


                        <IconButton color="primary" className={styles.ToggleRead} onClick={handleToggle}>
                            <ReadIcon className={classNames(classes())} tooltip={document?.state} />
                        </IconButton>
                    </Grid>
                </CardActions>
            </Card>
            <div className={styles.CommentsBoard}>
                <div onClick={handleClick} className={styles.CommentsArea}>
                    {document?.comments?.map((comment: any) => (
                        <ReferenceComment
                            key={comment.id}
                            comment={comment}
                            remove={handleRemove}
                            update={handleUpdateComment}
                        />))
                    }
                </div>
                <h4>{document?.comments?.length} comments</h4>
            </div>
        </Grid>
    );
};

export default DocumentDetails;
