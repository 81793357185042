import Grid from '@material-ui/core/Grid';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PaletteIcon from '@material-ui/icons/Palette';
import React, { useState } from 'react';
import FormatDate from '../FormatDate/FormatDate';
import styles from './ReferenceComment.module.scss';

const CommentHeader = ({ comment, remove, changeColor, expand }: { comment: any, remove: any, changeColor: any, expand: any }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const ExpandIcon = comment.collapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />;

    return (
        <Grid container justify="space-between" alignItems="center" className={styles.CommentHeader}>
            <AccountCircleIcon style={{ fontSize: '20px' }} />
            <span className={styles.Author}>
                {comment.user && comment.user.name}
            </span>

            {comment.collapsed && <span className={styles.CollapsedDescription}>
                <Tooltip title={comment.description}>
                    <span>...</span>
                </Tooltip>
            </span>
            }

            <span className={styles.Datetime}>
                <FormatDate date={comment.created} />
            </span>
            <MoreVertIcon style={{ fontSize: '20px', cursor: 'pointer' }} onClick={handleClick} />
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={expand}>
                    {ExpandIcon}
                    {comment.collapsed ? 'Expand' : 'Collapse'}
                </MenuItem>
                <MenuItem onClick={remove}>
                    <DeleteIcon />
                    Delete
		    </MenuItem>
                <MenuItem onClick={changeColor}>
                    <PaletteIcon />
                    Change Color
		    </MenuItem>

            </Menu>
        </Grid>
    );
};

const ReferenceComment = ({ comment, update, remove }: { comment: any, update: any, remove: any }) => {

    const [updatedComment, setUpdatedComment] = useState({ ...comment, status: 'UNPRESSED', offset_x: 0, offset_y: 0 });

    const handleRemove = (event: any) => {
        event.stopPropagation();
        remove(comment);
    };

    const changeColor = () => {
        const colors = [
            '#FFFF40',
            '#FFC900',
            '#64C7EC',
            '#EC64A2',
            '#1BF23C',
            '#FFBECA'
        ];
        const idx = colors.findIndex(i => i === updatedComment.color);
        const color = idx === colors.length - 1 ? colors[0] : colors[idx + 1];
        setUpdatedComment({ ...updatedComment, color });
        update({ id: updatedComment.id, color });
    };

    const handleExpand = () => {
        const collapsed = !updatedComment.collapsed;
        setUpdatedComment({ ...updatedComment, collapsed });
        update({ id: updatedComment.id, collapsed });
    };

    const handleOnMouseDown = (event: any) => {
        const x = event.nativeEvent.clientX - updatedComment.x;
        const y = event.nativeEvent.clientY - updatedComment.y;
        setUpdatedComment({ ...updatedComment, status: 'PRESSED', offset_x: x, offset_y: y });
    };

    const handleMouseMove = (event: any) => {
        if (updatedComment.status === 'PRESSED') {
            const x = event.nativeEvent.clientX - updatedComment.offset_x;
            const y = event.nativeEvent.clientY - updatedComment.offset_y;
            setUpdatedComment({ ...updatedComment, x, y });
        }
    };

    const handleOnMouseUp = () => {
        setUpdatedComment({ ...updatedComment, status: 'UNPRESSED', offset_x: 0, offset_y: 0 });
        update({ id: updatedComment.id, x: updatedComment.x, y: updatedComment.y });
    };

    const handleChange = (event: any) => {
        const value = event.target.value;
        setUpdatedComment({ ...updatedComment, description: value });
    };

    const handleBlur = () => {
        update({ id: updatedComment.id, description: updatedComment.description });
    };

    const style = { backgroundColor: updatedComment.color, top: updatedComment.y, left: updatedComment.x };

    return (
        <Grid container direction="row" id={styles.Comment} style={style}
            onMouseMove={handleMouseMove}
            onMouseDown={handleOnMouseDown}
            onMouseUp={handleOnMouseUp}
            onClick={event => event.stopPropagation()}
        >
            <CommentHeader
                comment={updatedComment}
                remove={handleRemove}
                changeColor={changeColor}
                expand={handleExpand}
            />
            {!updatedComment.collapsed && <div style={{ paddingBottom: 25, width: "100%" }}><TextField
                name='description'
                type='text'
                multiline
                autoFocus
                fullWidth
                value={updatedComment.description}
                onChange={handleChange}
                onBlur={handleBlur}
                onMouseDown={event => event.stopPropagation()}
            /></div>
            }
        </Grid>
    );
};

export default ReferenceComment;
