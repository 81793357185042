import Grid from '@material-ui/core/Grid';
import React from 'react';
import DocumentDetails from '../DocumentDetails/DocumentDetails';
import styles from './DocumentDetailsView.module.scss';

const DocumentDetailsView = (props: any) => (
    <Grid container justify="center">
        <Grid className={styles.DocumentCard}>
            <DocumentDetails id={props.match.params.id} />
        </Grid>
    </Grid>
);

export default DocumentDetailsView;
