import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.css';
import DocumentDetailsView from './components/DocumentDetailsView/DocumentDetailsView';
import DocumentsView from './components/DocumentsView/DocumentsView';
import ExplorationDetailsView from './components/ExplorationDetailsView/ExplorationDetailsView';
import ExplorationsView from './components/ExplorationsView/ExplorationsView';
import FleetingNotesView from './components/FleetingNotesView/FleetingNotesView';
import GistDetailsView from './components/GistDetailsView/GistDetailsView';
import GistsView from './components/GistsView/GistsView';
import GlobalFab from './components/GlobalFab/GlobalFab';
import Header from './components/Header/Header';
import Leading from './components/Leading/Leading';
import LinkDetailsView from './components/LinkDetailsView/LinkDetailsView';
import LinksView from './components/LinksView/LinksView';
import Login from './components/Login/Login';
import Logout from './components/Logout/Logout';
import ReferenceEditor from './components/ReferenceEditor/ReferenceEditor';
import ReferencesListView from './components/ReferencesListView/ReferencesListView';
import SearchView from './components/SearchView/SearchView';
import Sidemenu from "./components/Sidemenu/Sidemenu";
import TagsListView from './components/TagsListView/TagsListView';
import VideoPlayer from './components/VideoPlayer/VideoPlayer';
import client from "./services/Client";
import { FabProvider } from './services/FabProvider';
import theme from './theme';

const App = () => {

    const [action, setAction] = useState();

    return (
        <ApolloProvider client={client}>
            <Router>
                <MuiThemeProvider theme={theme}>
                    <Header />
                    <Leading />
                    <Sidemenu />
                    <FabProvider value={{ action, setAction }}>
                        <Route exact path="/" component={ExplorationsView} />
                        <Route exact path="/documents" component={DocumentsView} />
                        <Route exact path="/documents/:id" component={DocumentDetailsView} />
                        <Route exact path="/explorations" component={ExplorationsView} />
                        <Route exact path="/explorations/:id" component={ExplorationDetailsView} />
                        <Route exact path="/gists" component={GistsView} />
                        <Route exact path="/gists/:id" component={GistDetailsView} />
                        <Route exact path="/references" component={ReferencesListView} />
                        <Route exact path="/references/:id" component={ReferenceEditor} />
                        <Route exact path="/links" component={LinksView} />
                        <Route exact path="/links/:id" component={LinkDetailsView} />
                        <Route exact path="/login" component={Login} />
                        <Route exact path="/logout" component={Logout} />
                        <Route exact path="/tags" component={TagsListView} />
                        <Route exact path="/player/:id" component={VideoPlayer} />
                        <Route exact path="/fleeting" component={FleetingNotesView} />
                        <Route exact path="/search" component={SearchView} />
                        <GlobalFab />
                    </FabProvider>
                </MuiThemeProvider>
            </Router>
        </ApolloProvider>
    );
};

export default App;
