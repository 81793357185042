import { useMutation } from '@apollo/react-hooks';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Pagination from '@material-ui/lab/Pagination';
import React, { useState } from 'react';
import { useLinksQuery, useRemoveReferenceMutation } from '../../generated/graphql';
import { useGetPage } from '../../services/Hooks';
import { UPDATE_REFERENCE_TAGS } from '../../services/Queries';
import BulkActions from '../BulkActions/BulkActions';
import CustomModal from '../CustomModal/CustomModal';
import LinkPreview from '../LinkPreview/LinkPreview';
import LinksFiltersBar from '../LinksFiltersBar/LinksFiltersBar';
import ScopedSearch from '../ScopedSearch/ScopedSearch';
import TagReference from '../TagReference/TagReference';
import Toolbar from '../Toolbar/Toolbar';
import styles from './LinksList.module.scss';
import TagFilter from '../TagFilter/TagFilter';

const LinksListContent = ({ data, filters, handleSelect, selected }: { data: any, filters: any, handleSelect: any, selected: any }) => {
    return (
        <Grid container direction="row" justify="center" alignItems="flex-start" spacing={8} className={styles.GistsList}>
            {data.references.results.length === 0 && <p className={styles.EmptyState}>Sem Referências.</p>}
            {data.references.results.map(
                (reference: any) =>
                    <Grid item xs={3} key={reference.id}>
                        <LinkPreview link={reference} handleSelect={handleSelect} selected={selected.find((el: any) => el === reference.id)} />
                    </Grid>
            )}
        </Grid>
    );
};

const LinksList = () => {

    const page = useGetPage();
    const [filters, setFilters] = useState({ page, showFilters: false, search: '', tag: '' });
    const [selected, setSelected] = useState([]);
    const { loading, data, refetch } = useLinksQuery({ variables: { ...filters } })
    const [deleteReference] = useRemoveReferenceMutation()
    const [isTagModalOpen, setTagModalOpen] = useState(false);
    const [updateReferenceTags] = useMutation(UPDATE_REFERENCE_TAGS);


    if (loading) {
        return <CircularProgress />;
    }

    const handlePagination = (_: any, page: number) => {
        setFilters({ ...filters, page });
    };


    const handleSelect = (id: any, action: any) => {
        if (action === 'add') {
            // @ts-ignore
            setSelected([...selected, id]);
        } else {
            const newSelected = selected.filter(el => el !== id);
            setSelected(newSelected);
        }
    };

    const deselect = () => {
        setSelected([]);
    };

    const bulkDelete = () => {
        deselect();
        // @ts-ignore
        const promisses = selected.map(id => deleteReference({ variables: { id } }));
        // @ts-ignore
        Promise.all(promisses).then(refetch);
    };

    const bulkTag = () => {
        // @ts-ignore
        setTagModalOpen(true);
    };

    const tagReferences = (tags: any) => {
        // @ts-ignore
        setTagModalOpen(false);
        deselect();
        const promisses = selected.map(id => updateReferenceTags({ variables: { reference: id, tags: tags } }));
        // @ts-ignore
        Promise.all(promisses).then(refetch);
    };

    const search = (data: any) => {
        const searchTerm = data.fullSearch;
        setFilters({ ...filters, search: searchTerm });
        refetch();
    };

    const handleTagFilter = (_: any, data: any) => {
        setFilters({ ...filters, tag: data.id });
        refetch();
    }

    return (
        <Grid container direction="column" alignItems="center">
            <BulkActions
                visible={selected}
                selected={selected}
                deselect={deselect}
                bulkDelete={bulkDelete}
                bulkTag={bulkTag}
            />
            <Toolbar>
                <Grid container alignItems="center">
                    <ScopedSearch onSearch={search} value={filters.search} />
                    <TagFilter onFilter={handleTagFilter} value={filters.tag} />
                </Grid>
            </Toolbar>
            <LinksFiltersBar filters={filters} onFilter={setFilters} />
            <LinksListContent filters={filters} data={data} handleSelect={handleSelect} selected={selected} />

            <Pagination count={data?.references?.count ? Math.ceil(data.references.count / 25) : 0} page={filters.page} onChange={handlePagination} style={{ marginTop: 25, marginBottom: 25 }} />

            <CustomModal isActive={isTagModalOpen} title="Tag" handleClose={() => setTagModalOpen(false)}>

                <TagReference tagReferences={tagReferences} />
            </CustomModal>

        </Grid>
    );
};

export default LinksList;
