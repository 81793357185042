import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useState } from 'react';
import { useTagsQuery } from '../../generated/graphql';
import Loading from '../Loading/Loading';

const TagReference = ({ tagReferences }: { tagReferences: any }) => {

    const { data, loading } = useTagsQuery({ variables: { pageSize: 9999 } });
    const [tags, setTags] = useState([]);

    if (loading) {
        return <Loading />;
    }

    const handleChange = (event: any) => {
        setTags(event.target.value);
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Grid container direction="column">
            <FormControl>
                <InputLabel>Tags</InputLabel>
                <Select
                    multiple
                    value={tags}
                    onChange={handleChange}
                    input={<Input />}
                    MenuProps={MenuProps}
                    fullWidth
                >
                    // @ts-ignore
                    {data?.tags?.results.map((tag: any) => (
                        <MenuItem key={tag.id} value={tag.id}>
                            {tag.id}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Button onClick={event => tagReferences(tags)} style={{ marginTop: '25px' }}>Tag It!</Button>
        </Grid>
    );
};

export default TagReference;
