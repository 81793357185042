import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const ClipboardIcon = (props: any) => {
    const data = "M6.012 0c-0.611 0.010-1.103 0.508-1.103 1.121 0 0.006 0 0.011 0 0.017v-0.001 0.531c-2.776 0.453-4.909 2.867-4.909 5.767v10.714c0 3.222 2.629 5.85 5.85 5.85h12.388c3.222 0 5.85-2.629 5.85-5.85v-10.714c0-2.914-2.153-5.335-4.947-5.773v-0.525c0-0.005 0-0.010 0-0.016 0-0.619-0.502-1.121-1.121-1.121s-1.121 0.502-1.121 1.121c0 0.006 0 0.011 0 0.017v-0.001 0.448h-9.75v-0.448c0-0.005 0-0.010 0-0.016 0-0.619-0.502-1.121-1.121-1.121-0.006 0-0.012 0-0.018 0l0.001-0zM7.151 3.826h9.75v0.299c0 0.001 0 0.002 0 0.004s0 0.003 0 0.004v-0c0.004 0.689-0.528 1.226-1.217 1.226h-7.316c-0.689 0-1.221-0.537-1.217-1.226 0-0.001 0-0.002 0-0.004s0-0.003 0-0.004v0-0.299zM19.142 3.95c1.567 0.395 2.706 1.781 2.706 3.485v10.714c0 2.019-1.59 3.609-3.609 3.609h-12.388c-2.019 0-3.609-1.59-3.609-3.609v-10.714c0-1.69 1.121-3.065 2.668-3.473v0.156c-0.012 1.906 1.553 3.482 3.459 3.482h7.316c1.906 0 3.471-1.576 3.459-3.482v-0.168zM6.679 10.565c-0.005-0-0.010-0-0.016-0-0.619 0-1.121 0.502-1.121 1.121s0.502 1.121 1.121 1.121c0.006 0 0.011-0 0.017-0h8.666c0.005 0 0.010 0 0.016 0 0.619 0 1.121-0.502 1.121-1.121s-0.502-1.121-1.121-1.121c-0.006 0-0.011 0-0.017 0h-8.666zM6.679 15.781c-0.005-0-0.010-0-0.016-0-0.619 0-1.121 0.502-1.121 1.121s0.502 1.121 1.121 1.121c0.006 0 0.011-0 0.017-0h3.445c0.005 0 0.010 0 0.016 0 0.619 0 1.121-0.502 1.121-1.121s-0.502-1.121-1.121-1.121c-0.006 0-0.011 0-0.017 0h-3.445z";
    return (
        <SvgIcon {...props}>
            <path d={data} />
        </SvgIcon>
    );
};

export default ClipboardIcon;
