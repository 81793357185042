import Grid from '@material-ui/core/Grid';
import React from 'react';
import ExplorationDetails from '../ExplorationDetails/ExplorationDetails';
import styles from './ExplorationDetailsView.module.scss';

const ExplorationDetailsView = (props: any) => (
    <Grid container justify="center">
        <Grid className={styles.ExplorationCard}>
            <ExplorationDetails id={props.match.params.id} />
        </Grid>
    </Grid>
);

export default ExplorationDetailsView;
