import { Card, CardContent, CardHeader } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useState } from 'react';
import CustomModal from '../CustomModal/CustomModal';
import GistDetails from '../GistDetails/GistDetails';
import styles from './GistPreview.module.scss';

const GistPreviewMenu = (props: any) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event: any) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteReference = (event: any) => {
        event.stopPropagation();
        props.remove(props.gist.id);
    };

    return (
        <div>
            <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={deleteReference}>Delete</MenuItem>
            </Menu>
        </div>
    );
};

const GistPreview = ({ gist, remove }: { gist: any, remove: any }) => {
    const [itemModalOpen, setItemModalOpen] = useState(false);

    return (
        <Card className={styles.GistPreview} onClick={() => setItemModalOpen(true)}>
            <CardHeader title={gist.name} action={<GistPreviewMenu gist={gist} remove={remove} />} />
            <CardContent>
                <p>{gist.description}</p>
            </CardContent>
            <CustomModal isActive={itemModalOpen} title={gist.name} handleClose={() => setItemModalOpen(false)}>
                <GistDetails id={gist.id} />
            </CustomModal>
        </Card>
    );
};

export default GistPreview;
