import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React from 'react';

const SingleSelector = ({ name, data, value, onChange }: { name: any, data: any, value: any, onChange: any }) => {

    if (!data) {
        return null;
    }

    return (
        <FormControl>
            <InputLabel>{name}</InputLabel>
            <Select
                name={name}
                value={value}
                onChange={onChange}
            >
                {
                    data.referenceLanguages.results.map(
                        (language: any) => <MenuItem key={language.id} value={language.id}>{language.name}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
};

export default SingleSelector;
