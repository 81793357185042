import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useChangeHandler } from '../../services/Hooks';
import styles from './LinksFiltersBar.module.scss';

const Filters = ({ show, onFilter, filters }: { show: any, onFilter: any, filters: any }) => {

    const [obj, setObj] = useState({ fullSearch: '' });
    const changeHandler = useChangeHandler(obj, setObj);

    const classes = [styles.Filters];

    if (show) {
        classes.push(styles.Show);
    }

    const onSubmit = (event: any) => {
        event.preventDefault();
        onFilter({ ...filters, ...obj });
    };

    return (
        <form onSubmit={onSubmit}>
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classNames(classes)}>
                <Grid item xs={2}>
                    <FormControl>
                        <TextField
                            name="fullSearch"
                            margin="dense"
                            onChange={changeHandler}
                            value={obj.fullSearch || filters.fullSearch}
                            autoFocus
                            fullWidth
                            placeholder="pesquisa"
                        />
                    </FormControl>
                </Grid>
                <Button type="submit">GO</Button>
            </Grid>
        </form>
    );
};

const LinksFiltersBar = (props: any) => {

    const onShowFilters = (event: any) => {
        props.onFilter({ ...props.filters, showFilters: event.target.checked });
    };

    return (
        <>
            <Grid container direction="row" justify="flex-start" className={styles.FiltersBar}>
                <Switch
                    name="showFilters"
                    checked={props.filters.showFilters}
                    onChange={onShowFilters}
                />
            </Grid>
            <Filters show={props.filters.showFilters} onFilter={props.onFilter} filters={props.filters} />
        </>
    );
};

export default LinksFiltersBar;
