import Grid from '@material-ui/core/Grid';
import React from 'react';
import LinkDetails from '../LinkDetails/LinkDetails';
import styles from './LinkDetailsView.module.scss';

const LinkDetailsView = (props: any) => (
    <Grid container justify="center">
        <Grid className={styles.LinkCard}>
            <LinkDetails id={props.match.params.id} />
        </Grid>
    </Grid>
);

export default LinkDetailsView;
