import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import React from 'react';
import styles from './BulkActions.module.scss';

// @ts-ignore
const BulkActions = ({ visible, selected, deselect, bulkDelete, bulkTag, ...rest }) => {

    if (visible.length === 0) {
        return null;
    }

    const selectedMessage = rest.selectedMsg ? rest.selectedMsg : 'selected references';

    return (
        <AppBar position="fixed" className={styles.BulkActions}>
            <Toolbar>
                <Grid container justify="space-between">
                    <div>
                        <IconButton className={styles.menuButton} onClick={deselect}>
                            <CloseIcon />
                        </IconButton>
                        <span>{selected.length} {selectedMessage}</span>
                    </div>
                    <div>
                        <IconButton className={styles.menuButton} onClick={bulkTag}>
                            <LocalOfferIcon />
                        </IconButton>
                        <IconButton className={styles.menuButton} onClick={bulkDelete}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default BulkActions;
