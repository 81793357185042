import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React, { useContext } from 'react';
import { FabContext } from '../../services/FabProvider';
import styles from './GlobalFab.module.scss';

const GlobalFabButton = ({ action }: { action: any }) => {
    return (
        <Fab color="primary" className={styles.GlobalFabButton} onClick={action}>
            <AddIcon />
        </Fab>
    );
};

const GlobalFab = () => {
    const { action } = useContext(FabContext);
    return action ? <GlobalFabButton action={action} /> : null;
};

export default GlobalFab;
