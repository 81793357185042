import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useChangeHandler } from '../../services/Hooks';
import styles from './CreateTag.module.scss';

const CreateTag = ({ onComplete, handleSubmit }: { onComplete: any, handleSubmit: any }) => {

    const [tag, setTag] = useState();
    const changeHandler = useChangeHandler(tag, setTag);

    return (
        <Grid container direction="column" className={styles.CreateTag}>
            <TextField name="id" placeholder="name" onChange={changeHandler} autoFocus />
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <Button onClick={() => handleSubmit(tag)}>Create Tag</Button>
        </Grid>
    );
};

export default CreateTag;
