import Grid from '@material-ui/core/Grid';
import React from 'react';
import ReferencePreview from '../ReferencePreview/ReferencePreview';
import styles from './ReferencesList.module.scss';

const ReferencesList = ({ references, handleUpdate, selected, handleSelect }: { references: any, handleUpdate: any, selected: any, handleSelect: any }) => (
    <Grid item xs={10} className={styles.ReferencesList}>
        <Grid container direction="row" spacing={8} justify="flex-start" alignItems="center">
            {references.results.map(
                (reference: any) => (
                    <Grid key={reference.id} item xs={3}>
                        <ReferencePreview
                            reference={reference}
                            handleUpdate={handleUpdate}
                            selected={selected.find((el: any) => el === reference.id)}
                            handleSelect={handleSelect}
                        />
                    </Grid>
                )
            )}
        </Grid>
    </Grid>
);

export default ReferencesList;
