import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const PrayIcon = (props: any) => {
    const data = "M9.809 0c-0.24 0-0.48 0.103-0.583 0.309l-6.078 9.030c-0.652 0.961-1.030 2.060-1.133 3.228l-0.584 6.112h6.867l1.477-1.271c0.755-0.652 1.202-1.579 1.202-2.609v-6.043h-0.721c-0.309 0-0.584 0.172-0.687 0.481l-1.648 4.189c-0.137 0.378-0.583 0.584-0.961 0.412-0.378-0.137-0.584-0.584-0.412-0.962l1.648-4.189c0.343-0.858 1.167-1.442 2.094-1.442h0.721v-6.524c-0.034-0.378-0.343-0.721-0.755-0.721h-0.447zM13.242 0.034c-0.412 0-0.721 0.309-0.721 0.721v6.524h0.721c0.927 0 1.751 0.55 2.094 1.442l1.648 4.189c0.137 0.378-0.034 0.824-0.412 0.962s-0.824-0.035-0.962-0.412l-1.648-4.189c-0.103-0.275-0.378-0.481-0.687-0.481h-0.721v6.043c0 0.996 0.446 1.958 1.202 2.61l1.408 1.236h6.867l-0.549-6.112c-0.103-1.167-0.515-2.266-1.133-3.228l-6.078-8.995c-0.137-0.206-0.343-0.309-0.583-0.309h-0.447zM15.783 20.154l0.652 3.228c0.069 0.343 0.377 0.618 0.755 0.618h5.459c0.275 0 0.481-0.137 0.653-0.343s0.206-0.481 0.103-0.721l-1.064-2.782h-6.558zM1.123 20.189l-1.065 2.747c-0.103 0.24-0.068 0.515 0.103 0.721 0.137 0.206 0.378 0.343 0.653 0.343h5.459c0.378 0 0.686-0.275 0.755-0.618l0.652-3.193h-6.558z";
    return (
        <SvgIcon {...props} style={{ fontSize: '18px', paddingBottom: '4px' }}>
            <path d={data} />
        </SvgIcon>
    );
};

export default PrayIcon;
