import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useState } from 'react';
import { useCreateReferenceMutation, ReferenceTypes } from '../../generated/graphql';
import { useChangeHandler } from '../../services/Hooks';
import { DOCUMENTS } from '../../services/Queries';

const CreateDocument = ({ onComplete }: { onComplete: any }) => {

    const [link, setDocument] = useState();
    const [createDocument] = useCreateReferenceMutation()
    const changeHandler = useChangeHandler(link, setDocument);

    const handleSubmit = () => {
        createDocument({
            variables: { data: { ...link, type: ReferenceTypes.Document } },
            refetchQueries: [{ query: DOCUMENTS, variables: { page: 1 } }]
        }).then(() => onComplete());
    };

    return (
        <Grid container direction="column">
            <TextField required name="name" placeholder="name" onChange={changeHandler} />
            <TextField name="description" placeholder="description" multiline onChange={changeHandler} />
            <TextField required name="remoteFile" placeholder="remote file" onChange={changeHandler} />
            <Button onClick={handleSubmit}>Create Document</Button>
        </Grid>
    );
};

export default CreateDocument;
