import { useMutation } from '@apollo/react-hooks';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';
import { Link } from 'react-router-dom';
import { SET_MENU } from '../../services/Local';
// import GlobalSearch from '../GlobalSearch/GlobalSearch';
import styles from './Header.module.scss';

const Header = () => {

    const [setMenu] = useMutation(SET_MENU);

    return (
        <AppBar position="fixed">
            <Toolbar className={styles.AppToolbar}>
                <IconButton className={styles.menuButton} color="inherit" aria-label="Open drawer" onClick={() => setMenu({ variables: { state: true } })}>
                    <MenuIcon />
                </IconButton>
                <Typography className={styles.Title} variant="h6" color="inherit" noWrap>
                    <Link to='/references'>REFMAN</Link>
                </Typography>
                <div className={styles.Grow} />
                <div className={styles.SectionDesktop}>
                    {/*                     <GlobalSearch /> */}
                    <IconButton color="inherit">
                        <AccountCircle />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    );
};

export default Header;
